import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import AggregationLogin from "./stakeholders/aggregation/auth/login";
import CollectionsLogin from "./stakeholders/collections/auth/login";
import AggregationFPW from "./stakeholders/aggregation/auth/forgotPassword";
import AggregationOTP from "./stakeholders/aggregation/auth/otp";
import AggregationReset from "./stakeholders/aggregation/auth/resetPassword";
import CollectionFPW from "./stakeholders/collections/auth/forgotPassword";
import CollectionOTP from "./stakeholders/collections/auth/otp";
import CollectionReset from "./stakeholders/collections/auth/resetPassword";
import AggregationDashboard from "./stakeholders/aggregation/dashboard/aggregationDashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CollectionsDashboard from "./stakeholders/collections/dashboard";
import EntryPort from "./stakeholders";
import DriverLogin from "./stakeholders/drivers/auth/login";
import DriversFPW from "./stakeholders/drivers/auth/forgotPassword";
import DriversOTP from "./stakeholders/drivers/auth/otp";
import DriversReset from "./stakeholders/drivers/auth/resetPassword";
import DriversDashboard from "./stakeholders/drivers/dashboard/main";
import InitiatePickUp from "./stakeholders/drivers/dashboard/main/initiate";
import StartTransaction from "./stakeholders/drivers/dashboard/main/initiate/startTransaction";
import CalculateTransaction from "./stakeholders/drivers/dashboard/main/initiate/calculate";
import AwaitingConfirmation from "./stakeholders/drivers/dashboard/main/initiate/confirmaation";
import ReviewTransaction from "./stakeholders/drivers/dashboard/main/initiate/review";
import DriversWallet from "./stakeholders/drivers/dashboard/wallet";
import StartTransactionQuick from "./stakeholders/drivers/dashboard/main/initiate/startTransactionQuick";
import DriversMessages from "./stakeholders/drivers/dashboard/messages";
import MapDirection from "./stakeholders/drivers/dashboard/main/initiate/mapDirection";
import SuperAdminOTP from "./stakeholders/superAdmin/auth/otp";
import SuperAdminReset from "./stakeholders/superAdmin/auth/resetPassword";
import SuperAdminFPW from "./stakeholders/superAdmin/auth/forgotPassword";
import SuperAdminLogin from "./stakeholders/superAdmin/auth/login";
import SuperAdminDashboard from "./stakeholders/superAdmin/dashboard";
import DriversNotification from "./stakeholders/drivers/dashboard/notifications";
import PrivacyPolicy from "./stakeholders/privacyPolicy";

export const history = createBrowserHistory();

function App() {
  return (
    <div className="Apps">
      <Router history={history}>
        <Switch>
          {/* <Route path="/dashboards/pickup" render={(props) => authCheck(CollectionsDashnoard, props)} /> */}
          <Route
            path="/dashboards"
            render={(props) => authCheck2(CollectionsDashboard, props)}
          />
          <Route
            path="/dashboard"
            render={(props) => authCheck(AggregationDashboard, props)}
          />
          <Route
            path="/admin-dashboard"
            render={(props) => authCheck3(SuperAdminDashboard, props)}
          />
          <Route exact path="/drivers" component={DriverLogin} />
          <Route exact path="/drivers/forgotPassword" component={DriversFPW} />
          <Route exact path="/drivers/otp" component={DriversOTP} />
          <Route exact path="/drivers/reset" component={DriversReset} />
          <Route exact path="/drivers/dashboard" component={DriversDashboard} />
          <Route exact path="/drivers/initiate" component={InitiatePickUp} />
          <Route exact path="/drivers/start" component={StartTransaction} />
          <Route
            exact
            path="/drivers/notifications"
            component={DriversNotification}
          />
          <Route
            exact
            path="/drivers/startQuick"
            component={StartTransactionQuick}
          />
          <Route
            exact
            path="/drivers/calculate"
            component={CalculateTransaction}
          />
          <Route
            exact
            path="/drivers/awaitConfirmation"
            component={AwaitingConfirmation}
          />

          <Route exact path="/admin/otp" component={SuperAdminOTP} />
          <Route exact path="/admin/reset" component={SuperAdminReset} />
          <Route exact path="/admin/forgotPassword" component={SuperAdminFPW} />
          <Route exact path="/admin" component={SuperAdminLogin} />

          <Route exact path="/drivers/review" component={ReviewTransaction} />
          <Route exact path="/drivers/wallet" component={DriversWallet} />
          <Route exact path="/drivers/messages" component={DriversMessages} />
          <Route exact path="/drivers/direction" component={MapDirection} />

          <Route exact path="/aggregation/reset" component={AggregationReset} />
          <Route exact path="/aggregation/otp" component={AggregationOTP} />
          <Route
            exact
            path="/aggregation/forgotPassword"
            component={AggregationFPW}
          />
          <Route exact path="/aggregation" component={AggregationLogin} />
          <Route exact path="/collection/otp" component={CollectionOTP} />
          <Route exact path="/collection/reset" component={CollectionReset} />
          <Route
            exact
            path="/collection/forgotPassword"
            component={CollectionFPW}
          />
          <Route exact path="/collections" component={CollectionsLogin} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/" component={EntryPort} />

        </Switch>
      </Router>

      <ToastContainer />
    </div>
  );
}

function authCheck(Component, props) {
  if (localStorage.getItem("token")) {
    return <Component {...props} />;
  }
  return <Redirect to="/aggregation" />;
}

function authCheck2(Component, props) {
  if (localStorage.getItem("token")) {
    return <Component {...props} />;
  }
  return <Redirect to="/collections" />;
}

function authCheck3(Component, props) {
  if (localStorage.getItem("token")) {
    return <Component {...props} />;
  }
  return <Redirect to="/admin-dashboard" />;
}

export default App;
