import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { MdOutlineModeEdit } from "react-icons/md";
import Avatar from "../../../../../../../../assets/images/avatarr.png";
import { switchEndpoint } from "../../../../../../../../redux/actions/endpoints";
import "./index.css";
import {
  DROP_OFF_CANS_ABOVE_100,
  DROP_OFF_CANS_ABOVE_200,
  DROP_OFF_CANS_BELOW_100,
  DROP_OFF_CLEAN_SORTED_ABOVE_100,
  DROP_OFF_CLEAN_SORTED_ABOVE_200,
  DROP_OFF_CLEAN_SORTED_BELOW_100,
  DROP_OFF_CLEAN_UNSORTED_ABOVE_100,
  DROP_OFF_CLEAN_UNSORTED_ABOVE_200,
  DROP_OFF_CLEAN_UNSORTED_BELOW_100,
  DROP_OFF_HARD_PACKAGING_ABOVE_100,
  DROP_OFF_HARD_PACKAGING_ABOVE_200,
  DROP_OFF_HARD_PACKAGING_BELOW_100,
  DROP_OFF_HDPE_ABOVE_100,
  DROP_OFF_HDPE_ABOVE_200,
  DROP_OFF_HDPE_BELOW_100,
  DROP_OFF_MIXED_PLASTIC_ABOVE_100,
  DROP_OFF_MIXED_PLASTIC_ABOVE_200,
  DROP_OFF_MIXED_PLASTIC_BELOW_100,
  DROP_OFF_PACKAGING_BAGS_ABOVE_100,
  DROP_OFF_PACKAGING_BAGS_ABOVE_200,
  DROP_OFF_PACKAGING_BAGS_BELOW_100,
  DROP_OFF_PP_ABOVE_100,
  DROP_OFF_PP_ABOVE_200,
  DROP_OFF_PP_BELOW_100,
  DROP_OFF_PURE_WATER_ABOVE_100,
  DROP_OFF_PURE_WATER_ABOVE_200,
  DROP_OFF_PURE_WATER_BELOW_100,
  DROP_OFF_SINGLE_COLOR_ABOVE_100,
  DROP_OFF_SINGLE_COLOR_ABOVE_200,
  DROP_OFF_SINGLE_COLOR_BELOW_100,
} from "../../../../../../../../prices";
import { getPricing } from "../../../../../../../../pricingMethod";
import capitalize from "../../../../../../../../capitalize";

const categories = [
  "Clean Sorted",
  "Clean Unsorted",
  "Pure Water Nylon",
  "Single color caps",
  "Hard packing bags (dry)",
  "Mixed Plastics",
  "HDPE plastics below 5 Litres",
  "PP Plastics",
  "Pure Water Packaging Bags",
  "Cans",
];

const UnregistereDropCodeModal = ({
  showDrop,
  handleCloseDrop,
  dropOffData,
  handleShowAwaiting,
  steps,
  setSteps,
  handleShowPaymentRR,
  getHouseholdReq,
  getAgentReq,
  pricing,
  isManual,
  corporatePaymentMethod,
}) => {
  const [cleanSortedKg, setCleanSortedKg] = useState("");
  const [cleanSortedAmount, setCleanSortedAmount] = useState(null);
  const [cleanSortedDeduction, setCleanSortedDeduction] = useState("");

  const [cleanUnsortedKg, setCleanUnsortedKg] = useState("");
  const [cleanUnsortedAmount, setCleanUnsortedAmount] = useState(null);
  const [cleanUnsortedDeduction, setCleanUnsortedDeduction] = useState("");

  const [mixedPlasticsKg, setMixedPlasticsKg] = useState("");
  const [mixedPlasticsAmount, setMixedPlasticsAmount] = useState(null);
  const [mixedPlasticsDeduction, setMixedPlasticsDeduction] = useState("");

  const [pureWaterKg, setPureWaterKg] = useState("");
  const [pureWaterAmount, setPureWaterAmount] = useState(null);
  const [pureWaterDeduction, setPureWaterDeduction] = useState("");

  const [singleColorKg, setSingleColorKg] = useState("");
  const [singleColorAmount, setSingleColorAmount] = useState(null);
  const [singleColorDeduction, setSingleColorDeduction] = useState("");

  const [hardPackagingKg, setHardPackagingKg] = useState("");
  const [hardPackagingAmount, setHardPackagingAmount] = useState(null);
  const [hardPackagingDeduction, setHardPackagingDeduction] = useState("");

  const [hdpeKg, setHdpeKg] = useState("");
  const [hdpeAmount, setHdpeAmount] = useState(null);
  const [hdpeDeduction, setHdpeDeduction] = useState("");

  const [ppKg, setPpKg] = useState("");
  const [ppAmount, setPpAmount] = useState(null);
  const [ppDeduction, setPpDeduction] = useState("");

  const [packagingBagKg, setPackagingBagKg] = useState("");
  const [packagingBagAmount, setPackagingBagAmount] = useState(null);
  const [packagingBagDeduction, setPackagingBagDeduction] = useState("");

  const [cansKg, setCansKg] = useState("");
  const [cansAmount, setCansAmount] = useState(null);
  const [cansDeduction, setCansDeduction] = useState("");

  const calculateSortedVal = (val) => {
    if (cleanSortedDeduction !== "") {
      let price =
        getPricing(pricing, "clean_sorted", cleanSortedKg, "dropoff") *
        cleanSortedKg;
      const ded = val / 100;
      const df = ded * cleanSortedAmount;
      setDeduction1Val(df);
      setCleanSortedAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "clean_sorted", cleanSortedKg, "dropoff") *
        cleanSortedKg;
      const ded = 0 / 100;
      const df = ded * cleanSortedAmount;
      setDeduction1Val(df);
      setCleanSortedAmount(price - df);
    }
  };

  useEffect(() => {
    calculateSortedVal(cleanSortedDeduction);
  }, [cleanSortedDeduction]);

  const calculateUnsortedVal = (val) => {
    if (cleanUnsortedDeduction !== "") {
      let price =
        getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "dropoff") *
        cleanUnsortedKg;
      console.log(price);
      const ded = val / 100;
      const df = ded * cleanUnsortedAmount;
      setDeduction2Val(df);
      setCleanUnsortedAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "dropoff") *
        cleanUnsortedKg;
      const ded = 0 / 100;
      const df = ded * cleanUnsortedAmount;
      setDeduction2Val(df);
      setCleanUnsortedAmount(price - df);
    }
  };

  useEffect(() => {
    calculateUnsortedVal(cleanUnsortedDeduction);
  }, [cleanUnsortedDeduction]);

  const calculateMixedPlasticsVal = (val) => {
    if (mixedPlasticsDeduction !== "") {
      let price =
        getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "dropoff") *
        mixedPlasticsKg;
      const ded = val / 100;
      const df = ded * mixedPlasticsAmount;
      setDeduction3Val(df);
      setMixedPlasticsAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "dropoff") *
        mixedPlasticsKg;
      const ded = 0 / 100;
      const df = ded * mixedPlasticsAmount;
      setDeduction3Val(df);
      setMixedPlasticsAmount(price - df);
    }
  };

  useEffect(() => {
    calculateMixedPlasticsVal(mixedPlasticsDeduction);
  }, [mixedPlasticsDeduction]);

  const pureWaterVal = (val) => {
    if (pureWaterDeduction !== "") {
      let price =
        getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "dropoff") *
        pureWaterKg;
      const ded = val / 100;
      const df = ded * pureWaterAmount;
      setDeduction4Val(df);
      setPureWaterAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "dropoff") *
        pureWaterKg;
      const ded = 0 / 100;
      const df = ded * pureWaterAmount;
      setDeduction4Val(df);
      setPureWaterAmount(price - df);
    }
  };

  useEffect(() => {
    pureWaterVal(pureWaterDeduction);
  }, [pureWaterDeduction]);

  const singleColorVal = (val) => {
    if (singleColorDeduction !== "") {
      let price =
        getPricing(pricing, "single_colour_caps", singleColorKg, "dropoff") *
        singleColorKg;
      const ded = val / 100;
      const df = ded * singleColorAmount;
      setDeduction5Val(df);
      setSingleColorAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "single_colour_caps", singleColorKg, "dropoff") *
        singleColorKg;
      const ded = 0 / 100;
      const df = ded * singleColorAmount;
      setDeduction5Val(df);
      setSingleColorAmount(price - df);
    }
  };

  useEffect(() => {
    singleColorVal(singleColorDeduction);
  }, [singleColorDeduction]);

  const hardPackagingVal = (val) => {
    if (hardPackagingDeduction !== "") {
      let price =
        getPricing(
          pricing,
          "hard_packing_bags_dry",
          hardPackagingKg,
          "dropoff"
        ) * hardPackagingKg;
      const ded = val / 100;
      const df = ded * hardPackagingAmount;
      setDeduction6Val(df);
      setHardPackagingAmount(price - df);
    } else {
      let price =
        getPricing(
          pricing,
          "hard_packing_bags_dry",
          hardPackagingKg,
          "dropoff"
        ) * hardPackagingKg;
      const ded = 0 / 100;
      const df = ded * hardPackagingAmount;
      setDeduction6Val(df);
      setHardPackagingAmount(price - df);
    }
  };

  useEffect(() => {
    hardPackagingVal(hardPackagingDeduction);
  }, [hardPackagingDeduction]);

  const hdpeVal = (val) => {
    if (hdpeDeduction !== "") {
      let price =
        getPricing(
          pricing,
          "hdpe_plastics_below_five_meters",
          hdpeKg,
          "dropoff"
        ) * hdpeKg;
      const ded = val / 100;
      const df = ded * hdpeAmount;
      setDeduction7Val(df);
      setHdpeAmount(price - df);
    } else {
      let price =
        getPricing(
          pricing,
          "hdpe_plastics_below_five_meters",
          hdpeKg,
          "dropoff"
        ) * hdpeKg;
      const ded = 0 / 100;
      const df = ded * hdpeAmount;
      setDeduction7Val(df);
      setHdpeAmount(price - df);
    }
  };

  useEffect(() => {
    hdpeVal(hdpeDeduction);
  }, [hdpeDeduction]);

  const ppVal = (val) => {
    if (ppDeduction !== "") {
      let price = getPricing(pricing, "pp_plastics", ppKg, "dropoff") * ppKg;
      const ded = val / 100;
      const df = ded * ppAmount;
      setDeduction8Val(df);
      setPpAmount(price - df);
    } else {
      let price = getPricing(pricing, "pp_plastics", ppKg, "dropoff") * ppKg;
      const ded = 0 / 100;
      const df = ded * ppAmount;
      setDeduction8Val(df);
      setPpAmount(price - df);
    }
  };

  useEffect(() => {
    ppVal(ppDeduction);
  }, [ppDeduction]);

  const packagingBagVal = (val) => {
    if (packagingBagDeduction !== "") {
      let price =
        getPricing(pricing, "packing_bags", packagingBagKg, "dropoff") *
        packagingBagKg;
      const ded = val / 100;
      const df = ded * packagingBagAmount;
      setDeduction9Val(df);
      setPackagingBagAmount(price - df);
    } else {
      let price =
        getPricing(pricing, "packing_bags", packagingBagKg, "dropoff") *
        packagingBagKg;
      const ded = 0 / 100;
      const df = ded * packagingBagAmount;
      setDeduction9Val(df);
      setPackagingBagAmount(price - df);
    }
  };

  useEffect(() => {
    packagingBagVal(packagingBagDeduction);
  }, [packagingBagDeduction]);

  const cansVal = (val) => {
    if (cansDeduction !== "") {
      let price = getPricing(pricing, "cans", cansKg, "dropoff") * cansKg;
      const ded = val / 100;
      const df = ded * cansAmount;
      setDeduction10Val(df);
      setCansAmount(price - df);
    } else {
      let price = getPricing(pricing, "cans", cansKg, "dropoff") * cansKg;
      const ded = 0 / 100;
      const df = ded * cansAmount;
      setDeduction10Val(df);
      setCansAmount(price - df);
    }
  };

  useEffect(() => {
    cansVal(cansDeduction);
  }, [cansDeduction]);

  // console.log(cleanSortedKg);

  useEffect(() => {
    setCleanSortedAmount(
      getPricing(pricing, "clean_sorted", cleanSortedKg, "dropoff") *
      cleanSortedKg
    );
  }, [cleanSortedKg]);

  useEffect(() => {
    setCleanUnsortedAmount(
      getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "dropoff") *
      cleanUnsortedKg
    );
  }, [cleanUnsortedKg]);

  useEffect(() => {
    setMixedPlasticsAmount(
      getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "dropoff") *
      mixedPlasticsKg
    );
  }, [mixedPlasticsKg]);

  useEffect(() => {
    setPureWaterAmount(
      getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "dropoff") *
      pureWaterKg
    );
  }, [pureWaterKg]);

  useEffect(() => {
    setSingleColorAmount(
      getPricing(pricing, "single_colour_caps", singleColorKg, "dropoff") *
      singleColorKg
    );
  }, [singleColorKg]);

  useEffect(() => {
    setHardPackagingAmount(
      getPricing(pricing, "hard_packing_bags_dry", hardPackagingKg, "dropoff") *
      hardPackagingKg
    );
  }, [hardPackagingKg]);

  useEffect(() => {
    setHdpeAmount(
      getPricing(
        pricing,
        "hdpe_plastics_below_five_meters",
        hdpeKg,
        "dropoff"
      ) * hdpeKg
    );
  }, [hdpeKg]);

  useEffect(() => {
    setPpAmount(getPricing(pricing, "pp_plastics", ppKg, "dropoff") * ppKg);
  }, [ppKg]);

  useEffect(() => {
    setPackagingBagAmount(
      getPricing(pricing, "packing_bags", packagingBagKg, "dropoff") *
      packagingBagKg
    );
  }, [packagingBagKg]);

  useEffect(() => {
    setCansAmount(getPricing(pricing, "cans", cansKg, "dropoff") * cansKg);
  }, [cansKg]);

  const handleInputChangeCS = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setCleanSortedDeduction(value);
    }
  };

  const handleInputChangeCU = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setCleanUnsortedDeduction(value);
    }
  };

  const handleInputChangeMx = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setMixedPlasticsDeduction(value);
    }
  };

  const handleInputChangePw = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setPureWaterDeduction(value);
    }
  };

  const handleInputChangeSC = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setSingleColorDeduction(value);
    }
  };

  const handleInputChangeHP = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setHardPackagingDeduction(value);
    }
  };

  const handleInputChangeHD = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setHdpeDeduction(value);
    }
  };

  const handleInputChangePP = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setPpDeduction(value);
    }
  };

  const handleInputChangePB = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setPackagingBagDeduction(value);
    }
  };

  const handleInputChangeCans = (event) => {
    const { value } = event.target;
    // Allow the field to be cleared or match the specific pattern
    if (value === "" || /^(0|([1-9][0-9]*))(\.\d{0,2})?$/.test(value)) {
      setCansDeduction(value);
    }
  };
  // console.log(dropOffData);
  const [form, setForm] = useState({
    sorted: "",
    unsorted: "",
    mixed: "",
    pureWater: "",
    singleColor: "",
    hardPackaging: "",
    hdpe: "",
    pp: "",
    packagingBag: "",
    cans: "",
    deductSorted: "",
    deductUnsorted: "",
    deductMixed: "",
    deductSinglePureWater: "",
    deductSingleColor: "",
    deductHardPackaging: "",
    deductHdpe: "",
    deductPp: "",
    deductPackagingBag: "",
    deductCans: "",
  });
  const [sortedValue, setSortedValue] = useState(0);
  const [unsortedValue, setUnsortedValue] = useState(0);
  const [mixedValue, setMixedValue] = useState(0);
  const [pureWaterValue, setPureWaterValue] = useState(0);
  const [singleColorValue, setSingleColorValue] = useState(0);
  const [hardPackagingValue, setHardPackagingValue] = useState(0);
  const [hdpeValue, setHdpeValue] = useState(0);
  const [ppValue, setPpValue] = useState(0);
  const [packagingBagValue, setPackagingBagValue] = useState(0);
  const [cansValue, setCansValue] = useState(0);
  const [finalValue, setFinalValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deduction1, setDeduction1] = useState(false);
  const [deduction2, setDeduction2] = useState(false);
  const [deduction3, setDeduction3] = useState(false);
  const [deduction4, setDeduction4] = useState(false);
  const [deduction5, setDeduction5] = useState(false);
  const [deduction6, setDeduction6] = useState(false);
  const [deduction7, setDeduction7] = useState(false);
  const [deduction8, setDeduction8] = useState(false);
  const [deduction9, setDeduction9] = useState(false);
  const [deduction10, setDeduction10] = useState(false);
  const [deduction1Val, setDeduction1Val] = useState(0);
  const [deduction2Val, setDeduction2Val] = useState(0);
  const [deduction3Val, setDeduction3Val] = useState(0);
  const [deduction4Val, setDeduction4Val] = useState(0);
  const [deduction5Val, setDeduction5Val] = useState(0);
  const [deduction6Val, setDeduction6Val] = useState(0);
  const [deduction7Val, setDeduction7Val] = useState(0);
  const [deduction8Val, setDeduction8Val] = useState(0);
  const [deduction9Val, setDeduction9Val] = useState(0);
  const [deduction10Val, setDeduction10Val] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState("");
  const [selectedMethod, setSelectedMethod] = useState("");

  // console.log(steps);

  // start of new implementation
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };

  const handleInputClick = (event) => {
    // Prevent the click on the input from triggering the handleClickOutside event
    event.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const placeholderText = selectedCategories.length
    ? `${selectedCategories.join(", ")}`
    : "Select plastic categories";

  // Click outside handler
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //end of new implementation

  const [deductSS, setDeductSS] = useState("");

  const handleInputChangeCSs = (event) => {
    const value = event.target.value;
    if (/^([0-9](\.\d{0,2})?)?$/.test(event.target.value)) {
      setDeductSS(value);
    }
  };

  const handleFormChange =
    (name) =>
      ({ target }) => {
        if (/^([0-9](\.\d{0,2})?)?$/.test(target.value)) {
          setForm({
            ...form,
            [name]: target.value,
          });
        }
      };

  const handleFormChanges =
    (name) =>
      ({ target }) => {
        if (/^(\d*(\.\d{0,2})?)?$/.test(target.value)) {
          setForm({
            ...form,
            [name]: target.value,
          });
        }
      };

  const calculateSorted = (val) => {
    let conv = val;
    if (form.sorted > 0 && form.sorted < 100) {
      let calc1 = conv * DROP_OFF_CLEAN_SORTED_BELOW_100;
      const ded = deductSS / 100;
      const df = ded * calc1;
      setDeduction1Val(df);
      setSortedValue(calc1 - df);
    }
    if (form.sorted >= 100 && form.sorted < 200) {
      let calc1 = conv * DROP_OFF_CLEAN_SORTED_ABOVE_100;
      const ded = deductSS / 100;
      const df = ded * calc1;
      setDeduction1Val(df);
      setSortedValue(calc1 - df);
    }
    if (form.sorted >= 200) {
      let calc1 = conv * DROP_OFF_CLEAN_SORTED_ABOVE_200;
      const ded = deductSS / 100;
      const df = ded * calc1;
      setDeduction1Val(df);
      setSortedValue(calc1 - df);
    }
  };

  useEffect(() => {
    if (deductSS == form.sorted) {
      calculateSorted(form.sorted || 0, form.deductSorted);
    } else {
      calculateSorted(form.sorted || 0, form.deductSorted);
    }
  }, [deductSS, form.sorted, deductSS === form.sorted]);

  const calculateUnsorted = (val) => {
    if (form.unsorted > 0 && form.unsorted < 100) {
      let conv = val;
      let calc2 = conv * DROP_OFF_CLEAN_UNSORTED_BELOW_100;
      const ded = (form.deductUnsorted / 100) * calc2;
      setDeduction2Val(ded);
      setUnsortedValue(calc2 - ded);
    }
    if (form.unsorted >= 100 && form.unsorted < 200) {
      let conv = val;
      let calc2 = conv * DROP_OFF_CLEAN_UNSORTED_ABOVE_100;
      const ded = (form.deductUnsorted / 100) * calc2;
      setDeduction2Val(ded);
      setUnsortedValue(calc2 - ded);
    }
    if (form.unsorted >= 200) {
      let conv = val;
      let calc2 = conv * DROP_OFF_CLEAN_UNSORTED_ABOVE_200;
      const ded = (form.deductUnsorted / 100) * calc2;
      setDeduction2Val(ded);
      setUnsortedValue(calc2 - ded);
    }
  };

  useEffect(() => {
    calculateUnsorted(form.unsorted || 0);
  }, [form.deductUnsorted, form.unsorted]);

  const calculateMixed = (val) => {
    if (form.mixed > 0 && form.mixed < 100) {
      let conv = val;
      var calc3 = conv * DROP_OFF_MIXED_PLASTIC_BELOW_100;
      const ded = (form.deductMixed / 100) * calc3;
      setDeduction3Val(ded);
      setMixedValue(calc3 - ded);
    }
    if (form.mixed >= 100 && form.mixed < 200) {
      let conv = val;
      var calc3 = conv * DROP_OFF_MIXED_PLASTIC_ABOVE_100;
      const ded = (form.deductMixed / 100) * calc3;
      setDeduction3Val(ded);
      setMixedValue(calc3 - ded);
    }
    if (form.mixed >= 200) {
      let conv = val;
      var calc3 = conv * DROP_OFF_MIXED_PLASTIC_ABOVE_200;
      const ded = (form.deductMixed / 100) * calc3;
      setDeduction3Val(ded);
      setMixedValue(calc3 - ded);
    }
  };

  useEffect(() => {
    calculateMixed(form.mixed || 0);
  }, [form.deductMixed, form.mixed, form.deductMixed > 0]);

  const calculatePureWater = (val) => {
    if (form.pureWater > 0 && form.pureWater < 100) {
      let conv = val;
      var calc4 = conv * DROP_OFF_PURE_WATER_BELOW_100;
      const ded = (form.deductSinglePureWater / 100) * calc4;
      setDeduction4Val(ded);
      setPureWaterValue(calc4 - ded);
    }
    if (form.pureWater >= 100 && form.pureWater < 200) {
      let conv = val;
      var calc4 = conv * DROP_OFF_PURE_WATER_ABOVE_100;
      const ded = (form.deductSinglePureWater / 100) * calc4;
      setDeduction4Val(ded);
      setPureWaterValue(calc4 - ded);
    }
    if (form.pureWater >= 200) {
      let conv = val;
      var calc4 = conv * DROP_OFF_PURE_WATER_ABOVE_200;
      const ded = (form.deductSinglePureWater / 100) * calc4;
      setDeduction4Val(ded);
      setPureWaterValue(calc4 - ded);
    }
  };

  useEffect(() => {
    calculatePureWater(form.pureWater || 0);
  }, [
    form.deductSinglePureWater,
    form.pureWater,
    form.deductSinglePureWater > 0,
  ]);

  const calculateSingleColor = (val) => {
    if (form.singleColor > 0 && form.singleColor < 100) {
      let conv = val;
      var calc5 = conv * DROP_OFF_SINGLE_COLOR_BELOW_100;
      const ded = (form.deductSingleColor / 100) * calc5;
      setDeduction5Val(ded);
      setSingleColorValue(calc5 - ded);
    }
    if (form.singleColor >= 100 && form.singleColor < 200) {
      let conv = val;
      var calc5 = conv * DROP_OFF_SINGLE_COLOR_ABOVE_100;
      const ded = (form.deductSingleColor / 100) * calc5;
      setDeduction5Val(ded);
      setSingleColorValue(calc5 - ded);
    }
    if (form.singleColor >= 200) {
      let conv = val;
      var calc5 = conv * DROP_OFF_SINGLE_COLOR_ABOVE_200;
      const ded = (form.deductSingleColor / 100) * calc5;
      setDeduction5Val(ded);
      setSingleColorValue(calc5 - ded);
    }
  };

  useEffect(() => {
    calculateSingleColor(form.singleColor || 0);
  }, [form.deductSingleColor, form.singleColor, form.deductSingleColor > 0]);

  const calculateHardPackaging = (val) => {
    if (form.hardPackaging > 0 && form.hardPackaging < 100) {
      let conv = val;
      var calc6 = conv * DROP_OFF_HARD_PACKAGING_BELOW_100;
      const ded = (form.deductHardPackaging / 100) * calc6;
      setDeduction6Val(ded);
      setHardPackagingValue(calc6 - ded);
    }
    if (form.hardPackaging >= 100 && form.hardPackaging < 200) {
      let conv = val;
      var calc6 = conv * DROP_OFF_HARD_PACKAGING_ABOVE_100;
      const ded = (form.deductHardPackaging / 100) * calc6;
      setDeduction6Val(ded);
      setHardPackagingValue(calc6 - ded);
    }
    if (form.hardPackaging >= 200) {
      let conv = val;
      var calc6 = conv * DROP_OFF_HARD_PACKAGING_ABOVE_200;
      const ded = (form.deductHardPackaging / 100) * calc6;
      setDeduction6Val(ded);
      setHardPackagingValue(calc6 - ded);
    }
  };

  useEffect(() => {
    calculateHardPackaging(form.hardPackaging || 0);
  }, [
    form.deductHardPackaging,
    form.hardPackaging,
    form.deductHardPackaging > 0,
  ]);

  const calculateHdpe = (val) => {
    if (form.hdpe > 0 && form.hdpe < 100) {
      let conv = val;
      var calc7 = conv * DROP_OFF_HDPE_BELOW_100;
      const ded = (form.deductHdpe / 100) * calc7;
      setDeduction7Val(ded);
      setHdpeValue(calc7 - ded);
    }
    if (form.hdpe >= 100 && form.hdpe < 200) {
      let conv = val;
      var calc7 = conv * DROP_OFF_HDPE_ABOVE_100;
      const ded = (form.deductHdpe / 100) * calc7;
      setDeduction7Val(ded);
      setHdpeValue(calc7 - ded);
    }
    if (form.hdpe >= 200) {
      let conv = val;
      var calc7 = conv * DROP_OFF_HDPE_ABOVE_200;
      const ded = (form.deductHdpe / 100) * calc7;
      setDeduction7Val(ded);
      setHdpeValue(calc7 - ded);
    }
  };

  useEffect(() => {
    calculateHdpe(form.hdpe || 0);
  }, [form.deductHdpe, form.hdpe, form.deductHdpe > 0]);

  const calculatePp = (val) => {
    if (form.pp > 0 && form.pp < 100) {
      let conv = val;
      var calc8 = conv * DROP_OFF_PP_BELOW_100;
      const ded = (form.deductPp / 100) * calc8;
      setDeduction8Val(ded);
      setPpValue(calc8 - ded);
    }
    if (form.pp >= 100 && form.pp < 200) {
      let conv = val;
      var calc8 = conv * DROP_OFF_PP_ABOVE_100;
      const ded = (form.deductPp / 100) * calc8;
      setDeduction8Val(ded);
      setPpValue(calc8 - ded);
    }
    if (form.pp >= 200) {
      let conv = val;
      var calc8 = conv * DROP_OFF_PP_ABOVE_200;
      const ded = (form.deductPp / 100) * calc8;
      setDeduction8Val(ded);
      setPpValue(calc8 - ded);
    }
  };

  useEffect(() => {
    calculatePp(form.pp || 0);
  }, [form.deductPp, form.pp, form.deductPp > 0]);

  const calculatePackagingBag = (val) => {
    if (form.packagingBag > 0 && form.packagingBag < 100) {
      let conv = val;
      var calc9 = conv * DROP_OFF_PACKAGING_BAGS_BELOW_100;
      const ded = (form.deductPackagingBag / 100) * calc9;
      setDeduction9Val(ded);
      setPackagingBagValue(calc9 - ded);
    }
    if (form.packagingBag >= 100 && form.packagingBag < 200) {
      let conv = val;
      var calc9 = conv * DROP_OFF_PACKAGING_BAGS_ABOVE_100;
      const ded = (form.deductPackagingBag / 100) * calc9;
      setDeduction9Val(ded);
      setPackagingBagValue(calc9 - ded);
    }
    if (form.packagingBag >= 200) {
      let conv = val;
      var calc9 = conv * DROP_OFF_PACKAGING_BAGS_ABOVE_200;
      const ded = (form.deductPackagingBag / 100) * calc9;
      setDeduction9Val(ded);
      setPackagingBagValue(calc9 - ded);
    }
  };

  useEffect(() => {
    calculatePackagingBag(form.packagingBag || 0);
  }, [form.deductPackagingBag, form.packagingBag, form.deductPackagingBag > 0]);

  const calculateCans = (val) => {
    if (form.cans > 0 && form.cans < 100) {
      let conv = val;
      var calc10 = conv * DROP_OFF_CANS_BELOW_100;
      const ded = (form.deductCans / 100) * calc10;
      setDeduction10Val(ded);
      setCansValue(calc10 - ded);
    }
    if (form.cans >= 100 && form.cans < 200) {
      let conv = val;
      var calc10 = conv * DROP_OFF_CANS_ABOVE_100;
      const ded = (form.deductCans / 100) * calc10;
      setDeduction10Val(ded);
      setCansValue(calc10 - ded);
    }
    if (form.cans >= 200) {
      let conv = val;
      var calc10 = conv * DROP_OFF_CANS_ABOVE_200;
      const ded = (form.deductCans / 100) * calc10;
      setDeduction10Val(ded);
      setCansValue(calc10 - ded);
    }
  };

  useEffect(() => {
    calculateCans(form.cans || 0);
  }, [form.deductCans, form.cans, form.deductCans > 0]);

  const final = (
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    val7,
    val8,
    val9,
    val10
  ) => {
    const total =
      Number(val1) +
      Number(val2) +
      Number(val3) +
      Number(val4) +
      Number(val5) +
      Number(val6) +
      Number(val7) +
      Number(val8) +
      Number(val9) +
      Number(val10);
    const dd = Math.round((total + Number.EPSILON) * 100) / 100;
    setFinalValue(dd);
  };

  const finalDeduction = (
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    val7,
    val8,
    val9,
    val10
  ) => {
    const total =
      Number(val1) +
      Number(val2) +
      Number(val3) +
      Number(val4) +
      Number(val5) +
      Number(val6) +
      Number(val7) +
      Number(val8) +
      Number(val9) +
      Number(val10);
    const dd = Math.round((total + Number.EPSILON) * 100) / 100;
    setTotalDeduction(dd);
  };

  useEffect(() => {
    finalDeduction(
      deduction1Val,
      deduction2Val,
      deduction3Val,
      deduction4Val,
      deduction5Val,
      deduction6Val,
      deduction7Val,
      deduction8Val,
      deduction9Val,
      deduction10Val
    );
  }, [
    deduction1Val,
    deduction2Val,
    deduction3Val,
    deduction4Val,
    deduction5Val,
    deduction6Val,
    deduction7Val,
    deduction8Val,
    deduction9Val,
    deduction10Val,
  ]);

  useEffect(() => {
    final(
      cleanSortedAmount,
      cleanUnsortedAmount,
      mixedPlasticsAmount,
      pureWaterAmount,
      singleColorAmount,
      hardPackagingAmount,
      hdpeAmount,
      ppAmount,
      packagingBagAmount,
      cansAmount
    );
  }, [
    cleanSortedAmount,
    cleanUnsortedAmount,
    mixedPlasticsAmount,
    pureWaterAmount,
    singleColorAmount,
    hardPackagingAmount,
    hdpeAmount,
    ppAmount,
    packagingBagAmount,
    cansAmount,
  ]);

  const initiateDropOff = () => {
    if (dropOffData?.owner?.roles[0] !== "corporate") {
      if (selectedMethod === "") {
        toast.error("Kindly select a payment method", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        return;
      } else {
        setLoading(true);
        const token = localStorage.getItem("token");
        const id = localStorage.getItem("id");

        let waste_category = [];

        if (cleanSortedKg) {
          waste_category.push({
            name: "clean_sorted",
            kg: Number(cleanSortedKg),
            deduction: cleanSortedDeduction ? Number(cleanSortedDeduction) : 0,
          });
        }

        if (cleanUnsortedKg) {
          waste_category.push({
            name: "clean_unsorted",
            kg: Number(cleanUnsortedKg),
            deduction: cleanUnsortedDeduction
              ? Number(cleanUnsortedDeduction)
              : 0,
          });
        }

        if (mixedPlasticsKg) {
          waste_category.push({
            name: "mixed_plastics",
            kg: Number(mixedPlasticsKg),
            deduction: mixedPlasticsDeduction
              ? Number(mixedPlasticsDeduction)
              : 0,
          });
        }

        if (pureWaterKg) {
          waste_category.push({
            name: "pure_water_sachet_dry",
            kg: Number(pureWaterKg),
            deduction: pureWaterDeduction ? Number(pureWaterDeduction) : 0,
          });
        }

        if (packagingBagKg) {
          waste_category.push({
            name: "packing_bags",
            kg: Number(packagingBagKg),
            deduction: packagingBagDeduction ? Number(packagingBagDeduction) : 0,
          });
        }

        if (singleColorKg) {
          waste_category.push({
            name: "single_colour_caps",
            kg: Number(singleColorKg),
            deduction: singleColorDeduction ? Number(singleColorDeduction) : 0,
          });
        }

        if (ppKg) {
          waste_category.push({
            name: "pp_plastics",
            kg: Number(ppKg),
            deduction: ppDeduction ? Number(ppDeduction) : 0,
          });
        }

        if (hardPackagingKg) {
          waste_category.push({
            name: "hard_packing_bags_dry",
            kg: Number(hardPackagingKg),
            deduction: hardPackagingDeduction
              ? Number(hardPackagingDeduction)
              : 0,
          });
        }

        if (hdpeKg) {
          waste_category.push({
            name: "hdpe_plastics_below_five_meters",
            kg: Number(hdpeKg),
            deduction: hdpeDeduction ? Number(hdpeDeduction) : 0,
          });
        }

        if (cansKg) {
          waste_category.push({
            name: "cans",
            kg: Number(cansKg),
            deduction: cansDeduction ? Number(cansDeduction) : 0,
          });
        }

        const payload = {
          waste_category: waste_category,
          dropoff_id: id,
          payment_method: dropOffData?.owner?.roles[0] === "corporate" ? "transfer" : selectedMethod,
          request_type: "dropoff",
        };
        axios
          .post(
            `${switchEndpoint}/collection-center/initiate-transaction`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            // console.log(response.data);
            localStorage.setItem("dropOff", JSON.stringify(response.data));
            if (dropOffData?.owner?.user_type === "unregistered") {
              setLoading(false);
              setForm({
                sorted: "",
                unsorted: "",
                mixed: "",
                pureWater: "",
                singleColor: "",
                hardPackaging: "",
                hdpe: "",
                pp: "",
                cans: "",
                deductSorted: "",
                deductUnsorted: "",
                deductMixed: "",
                deductSinglePureWater: "",
                deductSingleColor: "",
                deductHardPackaging: "",
                deductHdpe: "",
                deductPp: "",
                deductCans: "",
              });
              setSortedValue(0);
              setUnsortedValue(0);
              setMixedValue(0);
              setPureWaterValue(0);
              setSingleColorValue(0);
              setHardPackagingValue(0);
              setHdpeValue(0);
              setPpValue(0);
              setPackagingBagValue(0);
              setCansValue(0);
              setDeduction1(false);
              setDeduction2(false);
              setDeduction3(false);
              setDeduction4(false);
              setDeduction5(false);
              setDeduction6(false);
              setDeduction7(false);
              setDeduction8(false);
              setDeduction9(false);
              setDeduction10(false);
              setTotalDeduction(0);
              handleCloseDrop();
              handleShowPaymentRR();
            } else {
              setSteps(1);
              setLoading(false);
              setForm({
                sorted: "",
                unsorted: "",
                mixed: "",
                pureWater: "",
                singleColor: "",
                hardPackaging: "",
                hdpe: "",
                pp: "",
                cans: "",
                deductSorted: "",
                deductUnsorted: "",
                deductMixed: "",
                deductSinglePureWater: "",
                deductSingleColor: "",
                deductHardPackaging: "",
                deductHdpe: "",
                deductPp: "",
                deductCans: "",
              });
              setSortedValue(0);
              setUnsortedValue(0);
              setMixedValue(0);
              setPureWaterValue(0);
              setSingleColorValue(0);
              setHardPackagingValue(0);
              setHdpeValue(0);
              setPpValue(0);
              setPackagingBagValue(0);
              setCansValue(0);
              setDeduction1(false);
              setDeduction2(false);
              setDeduction3(false);
              setDeduction4(false);
              setDeduction5(false);
              setDeduction6(false);
              setDeduction7(false);
              setDeduction8(false);
              setDeduction9(false);
              setDeduction10(false);
              setTotalDeduction(0);
              handleCloseDrop();
              handleShowAwaiting();
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
          });
      }
    }
    else {
      setLoading(true);
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");

      let waste_category = [];

      if (cleanSortedKg) {
        waste_category.push({
          name: "clean_sorted",
          kg: Number(cleanSortedKg),
          deduction: cleanSortedDeduction ? Number(cleanSortedDeduction) : 0,
        });
      }

      if (cleanUnsortedKg) {
        waste_category.push({
          name: "clean_unsorted",
          kg: Number(cleanUnsortedKg),
          deduction: cleanUnsortedDeduction
            ? Number(cleanUnsortedDeduction)
            : 0,
        });
      }

      if (mixedPlasticsKg) {
        waste_category.push({
          name: "mixed_plastics",
          kg: Number(mixedPlasticsKg),
          deduction: mixedPlasticsDeduction
            ? Number(mixedPlasticsDeduction)
            : 0,
        });
      }

      if (pureWaterKg) {
        waste_category.push({
          name: "pure_water_sachet_dry",
          kg: Number(pureWaterKg),
          deduction: pureWaterDeduction ? Number(pureWaterDeduction) : 0,
        });
      }

      if (packagingBagKg) {
        waste_category.push({
          name: "packing_bags",
          kg: Number(packagingBagKg),
          deduction: packagingBagDeduction ? Number(packagingBagDeduction) : 0,
        });
      }

      if (singleColorKg) {
        waste_category.push({
          name: "single_colour_caps",
          kg: Number(singleColorKg),
          deduction: singleColorDeduction ? Number(singleColorDeduction) : 0,
        });
      }

      if (ppKg) {
        waste_category.push({
          name: "pp_plastics",
          kg: Number(ppKg),
          deduction: ppDeduction ? Number(ppDeduction) : 0,
        });
      }

      if (hardPackagingKg) {
        waste_category.push({
          name: "hard_packing_bags_dry",
          kg: Number(hardPackagingKg),
          deduction: hardPackagingDeduction
            ? Number(hardPackagingDeduction)
            : 0,
        });
      }

      if (hdpeKg) {
        waste_category.push({
          name: "hdpe_plastics_below_five_meters",
          kg: Number(hdpeKg),
          deduction: hdpeDeduction ? Number(hdpeDeduction) : 0,
        });
      }

      if (cansKg) {
        waste_category.push({
          name: "cans",
          kg: Number(cansKg),
          deduction: cansDeduction ? Number(cansDeduction) : 0,
        });
      }

      const payload = {
        waste_category: waste_category,
        dropoff_id: id,
        payment_method: dropOffData?.owner?.roles[0] === "corporate" ? "transfer" : selectedMethod,
        request_type: "dropoff",
      };
      axios
        .post(
          `${switchEndpoint}/collection-center/initiate-transaction`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          // console.log(response.data);
          localStorage.setItem("dropOff", JSON.stringify(response.data));
          if (dropOffData?.owner?.user_type === "unregistered") {
            setLoading(false);
            setForm({
              sorted: "",
              unsorted: "",
              mixed: "",
              pureWater: "",
              singleColor: "",
              hardPackaging: "",
              hdpe: "",
              pp: "",
              cans: "",
              deductSorted: "",
              deductUnsorted: "",
              deductMixed: "",
              deductSinglePureWater: "",
              deductSingleColor: "",
              deductHardPackaging: "",
              deductHdpe: "",
              deductPp: "",
              deductCans: "",
            });
            setSortedValue(0);
            setUnsortedValue(0);
            setMixedValue(0);
            setPureWaterValue(0);
            setSingleColorValue(0);
            setHardPackagingValue(0);
            setHdpeValue(0);
            setPpValue(0);
            setPackagingBagValue(0);
            setCansValue(0);
            setDeduction1(false);
            setDeduction2(false);
            setDeduction3(false);
            setDeduction4(false);
            setDeduction5(false);
            setDeduction6(false);
            setDeduction7(false);
            setDeduction8(false);
            setDeduction9(false);
            setDeduction10(false);
            setTotalDeduction(0);
            handleCloseDrop();
            handleShowPaymentRR();
          } else {
            setSteps(1);
            setLoading(false);
            setForm({
              sorted: "",
              unsorted: "",
              mixed: "",
              pureWater: "",
              singleColor: "",
              hardPackaging: "",
              hdpe: "",
              pp: "",
              cans: "",
              deductSorted: "",
              deductUnsorted: "",
              deductMixed: "",
              deductSinglePureWater: "",
              deductSingleColor: "",
              deductHardPackaging: "",
              deductHdpe: "",
              deductPp: "",
              deductCans: "",
            });
            setSortedValue(0);
            setUnsortedValue(0);
            setMixedValue(0);
            setPureWaterValue(0);
            setSingleColorValue(0);
            setHardPackagingValue(0);
            setHdpeValue(0);
            setPpValue(0);
            setPackagingBagValue(0);
            setCansValue(0);
            setDeduction1(false);
            setDeduction2(false);
            setDeduction3(false);
            setDeduction4(false);
            setDeduction5(false);
            setDeduction6(false);
            setDeduction7(false);
            setDeduction8(false);
            setDeduction9(false);
            setDeduction10(false);
            setTotalDeduction(0);
            handleCloseDrop();
            handleShowAwaiting();
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        });
    }
  };

  const initiateManualDropOff = () => {
    if (dropOffData?.owner?.roles[0] !== "corporate") {
      if (!selectedMethod) {
        toast.error("You must select a payment method", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
        return;
      } else {
        setLoading(true);
        const token = localStorage.getItem("token");
        const id = localStorage.getItem("id");

        let waste_category = [];

        if (isCsToggled && totalSorted !== 0) {
          waste_category.push({
            name: "clean_sorted",
            kg: Number(formManual.sorted),
            deduction: 0,
          });
        }

        if (!isCsToggled && cleanSortedAmount !== 0) {
          waste_category.push({
            name: "clean_sorted",
            kg: Number(cleanSortedKg),
            deduction: 0,
          });
        }


        if (isCUToggled && totalUnsorted !== 0) {
          waste_category.push({
            name: "clean_unsorted",
            kg: Number(formManual.unsorted),
            deduction: 0,
          });
        }

        if (!isCUToggled && cleanUnsortedAmount !== 0) {
          waste_category.push({
            name: "clean_unsorted",
            kg: Number(cleanUnsortedKg),
            deduction: 0
          });
        }

        if (isMixedToggled && totalMixed !== 0) {
          waste_category.push({
            name: "mixed_plastics",
            kg: Number(formManual.mixed),
            deduction: 0,
          });
        }

        if (!isMixedToggled && mixedPlasticsAmount !== 0) {
          waste_category.push({
            name: "mixed_plastics",
            kg: Number(mixedPlasticsKg),
            deduction: 0,
          });
        }

        if (isPureToggled && totalPure !== 0) {
          waste_category.push({
            name: "pure_water_sachet_dry",
            kg: Number(formManual.pureWater),
            deduction: 0,
          });
        }

        if (!isPureToggled && pureWaterAmount !== 0) {
          waste_category.push({
            name: "pure_water_sachet_dry",
            kg: Number(pureWaterKg),
            deduction: 0,
          });
        }

        if (isPackagingToggled && totalPackaging !== 0) {
          waste_category.push({
            name: "packing_bags",
            kg: Number(formManual.packagingBag),
            deduction: 0,
          });
        }

        if (!isPackagingToggled && packagingBagAmount !== 0) {
          waste_category.push({
            name: "packing_bags",
            kg: Number(packagingBagKg),
            deduction: 0,
          });
        }

        if (isSingleToggled && totalSingleColor !== 0) {
          waste_category.push({
            name: "single_colour_caps",
            kg: Number(formManual.singleColor),
            deduction: 0,
          });
        }

        if (!isSingleToggled && singleColorAmount !== 0) {
          waste_category.push({
            name: "single_colour_caps",
            kg: Number(singleColorKg),
            deduction: 0,
          });
        }

        if (isPpToggled && totalPp !== 0) {
          waste_category.push({
            name: "pp_plastics",
            kg: Number(formManual.pp),
            deduction: 0,
          });
        }

        if (!isPpToggled && ppAmount !== 0) {
          waste_category.push({
            name: "pp_plastics",
            kg: Number(ppKg),
            deduction: 0,
          });
        }

        if (isHardToggled && totalHard !== 0) {
          waste_category.push({
            name: "hard_packing_bags_dry",
            kg: Number(formManual.hardPackaging),
            deduction: 0,
          });
        }

        if (!isHardToggled && hardPackagingAmount !== 0) {
          waste_category.push({
            name: "hard_packing_bags_dry",
            kg: Number(hardPackagingKg),
            deduction: 0,
          });
        }

        if (isHdpeToggled && totalHdpe !== 0) {
          waste_category.push({
            name: "hdpe_plastics_below_five_meters",
            kg: Number(formManual.hdpe),
            deduction: 0,
          });
        }

        if (!isHardToggled && hdpeAmount !== 0) {
          waste_category.push({
            name: "hdpe_plastics_below_five_meters",
            kg: Number(hdpeKg),
            deduction: 0,
          });
        }

        if (iscansToggled && totalCans !== 0) {
          waste_category.push({
            name: "cans",
            kg: Number(formManual.cans),
            deduction: 0,
          });
        }

        if (!iscansToggled && cansAmount !== 0) {
          waste_category.push({
            name: "cans",
            kg: Number(cansKg),
            deduction: 0,
          });
        }

        const payload = {
          waste_category: waste_category,
          dropoff_id: id,
          payment_method: dropOffData?.owner?.roles[0] === "corporate" ? "transfer" : selectedMethod,
          request_type: "dropoff",
          manual_amount: totalManual,
        };

        // console.log(payload);

        axios
          .post(
            `${switchEndpoint}/collection-center/initiate-transaction`,
            payload,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            localStorage.setItem("dropOff", JSON.stringify(response.data));
            localStorage.setItem("totalDed", JSON.stringify(totalDeduction));
            // console.log(response.data);
            if (dropOffData?.owner?.user_type === "unregistered") {
              setLoading(false);
              handleCloseDrop();
              setFormManual({
                sorted: "",
                unsorted: "",
                mixed: "",
                pureWater: "",
                singleColor: "",
                hardPackaging: "",
                hdpe: "",
                pp: "",
                packagingBag: "",
                cans: "",
                manualSorted: "",
                manualUnsorted: "",
                manualMixed: "",
                manualSinglePureWater: "",
                manualSingleColor: "",
                manualHardPackaging: "",
                manualHdpe: "",
                manualPp: "",
                manualPackagingBag: "",
                manualCans: "",
              });
              setTotalSorted(0);
              setTotalUnsorted(0);
              setTotalMixed(0);
              setTotalPure(0);
              setTotalSingleColor(0);
              setTotalHard(0);
              setTotalHdpe(0);
              setTotalPp(0);
              setTotalPackaging(0);
              setTotalCans(0);
              setTotalManual(0);
              setIsCsToggled(false);
              setIsCUToggled(false);
              setIsMixedToggled(false);
              setIsPureToggled(false);
              setIsSingleToggled(false);
              setIsHardToggled(false);
              setIsHdpeToggled(false);
              setIsPpToggled(false);
              setIsPackagingToggled(false);
              setIscansToggled(false);
              setCleanSortedKg("");
              setCleanUnsortedKg("");
              setMixedPlasticsKg("");
              setPureWaterKg("");
              setSingleColorKg("");
              setHardPackagingKg("");
              setHdpeKg("");
              setPpKg("");
              setPackagingBagKg("");
              setCansKg("");
              setCleanSortedAmount(null);
              setCleanUnsortedAmount(null);
              setMixedPlasticsAmount(null);
              setPureWaterAmount(null);
              setSingleColorAmount(null);
              setHardPackagingAmount(null);
              setHdpeAmount(null);
              setPpAmount(null);
              setPackagingBagAmount(null);
              setCansAmount(null);
              handleShowPaymentRR();
              setSelectedCategories([]);
            } else {
              setSteps(1);
              setLoading(false);
              handleCloseDrop();
              setForm({
                sorted: "",
                unsorted: "",
                mixed: "",
                pureWater: "",
                singleColor: "",
                hardPackaging: "",
                hdpe: "",
                pp: "",
                cans: "",
                packagingBag: "",
                deductSorted: "",
                deductUnsorted: "",
                deductMixed: "",
                deductSinglePureWater: "",
                deductSingleColor: "",
                deductHardPackaging: "",
                deductHdpe: "",
                deductPp: "",
                deductCans: "",
              });
              setDeductSS("");
              setSortedValue(0);
              setUnsortedValue(0);
              setMixedValue(0);
              setPureWaterValue(0);
              setSingleColorValue(0);
              setHardPackagingValue(0);
              setHdpeValue(0);
              setPpValue(0);
              setPackagingBagValue(0);
              setCansValue(0);
              setDeduction1(false);
              setDeduction2(false);
              setDeduction3(false);
              setDeduction4(false);
              setDeduction5(false);
              setDeduction6(false);
              setDeduction7(false);
              setDeduction8(false);
              setDeduction9(false);
              setDeduction10(false);
              setTotalDeduction(0);
              setTotalManual(0);
              setIsCsToggled(false);
              setIsCUToggled(false);
              setIsMixedToggled(false);
              setIsPureToggled(false);
              setIsSingleToggled(false);
              setIsHardToggled(false);
              setIsHdpeToggled(false);
              setIsPpToggled(false);
              setIsPackagingToggled(false);
              setIscansToggled(false);
              setCleanSortedKg("");
              setCleanUnsortedKg("");
              setMixedPlasticsKg("");
              setPureWaterKg("");
              setSingleColorKg("");
              setHardPackagingKg("");
              setHdpeKg("");
              setPpKg("");
              setPackagingBagKg("");
              setCansKg("");
              setCleanSortedAmount(null);
              setCleanUnsortedAmount(null);
              setMixedPlasticsAmount(null);
              setPureWaterAmount(null);
              setSingleColorAmount(null);
              setHardPackagingAmount(null);
              setHdpeAmount(null);
              setPpAmount(null);
              setPackagingBagAmount(null);
              setCansAmount(null);
              setSelectedMethod("");
              handleShowAwaiting();
              setSelectedCategories([]);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              pauseOnHover: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
          });
      }
    } else {
      setLoading(true);
      const token = localStorage.getItem("token");
      const id = localStorage.getItem("id");

      let waste_category = [];

      if (isCsToggled && totalSorted !== 0) {
        waste_category.push({
          name: "clean_sorted",
          kg: Number(formManual.sorted),
          deduction: 0,
        });
      }

      if (!isCsToggled && cleanSortedAmount !== 0) {
        waste_category.push({
          name: "clean_sorted",
          kg: Number(cleanSortedKg),
          deduction: 0,
        });
      }


      if (isCUToggled && totalUnsorted !== 0) {
        waste_category.push({
          name: "clean_unsorted",
          kg: Number(formManual.unsorted),
          deduction: 0,
        });
      }

      if (!isCUToggled && cleanUnsortedAmount !== 0) {
        waste_category.push({
          name: "clean_unsorted",
          kg: Number(cleanUnsortedKg),
          deduction: 0
        });
      }

      if (isMixedToggled && totalMixed !== 0) {
        waste_category.push({
          name: "mixed_plastics",
          kg: Number(formManual.mixed),
          deduction: 0,
        });
      }

      if (!isMixedToggled && mixedPlasticsAmount !== 0) {
        waste_category.push({
          name: "mixed_plastics",
          kg: Number(mixedPlasticsKg),
          deduction: 0,
        });
      }

      if (isPureToggled && totalPure !== 0) {
        waste_category.push({
          name: "pure_water_sachet_dry",
          kg: Number(formManual.pureWater),
          deduction: 0,
        });
      }

      if (!isPureToggled && pureWaterAmount !== 0) {
        waste_category.push({
          name: "pure_water_sachet_dry",
          kg: Number(pureWaterKg),
          deduction: 0,
        });
      }

      if (isPackagingToggled && totalPackaging !== 0) {
        waste_category.push({
          name: "packing_bags",
          kg: Number(formManual.packagingBag),
          deduction: 0,
        });
      }

      if (!isPackagingToggled && packagingBagAmount !== 0) {
        waste_category.push({
          name: "packing_bags",
          kg: Number(packagingBagKg),
          deduction: 0,
        });
      }

      if (isSingleToggled && totalSingleColor !== 0) {
        waste_category.push({
          name: "single_colour_caps",
          kg: Number(formManual.singleColor),
          deduction: 0,
        });
      }

      if (!isSingleToggled && singleColorAmount !== 0) {
        waste_category.push({
          name: "single_colour_caps",
          kg: Number(singleColorKg),
          deduction: 0,
        });
      }

      if (isPpToggled && totalPp !== 0) {
        waste_category.push({
          name: "pp_plastics",
          kg: Number(formManual.pp),
          deduction: 0,
        });
      }

      if (!isPpToggled && ppAmount !== 0) {
        waste_category.push({
          name: "pp_plastics",
          kg: Number(ppKg),
          deduction: 0,
        });
      }

      if (isHardToggled && totalHard !== 0) {
        waste_category.push({
          name: "hard_packing_bags_dry",
          kg: Number(formManual.hardPackaging),
          deduction: 0,
        });
      }

      if (!isHardToggled && hardPackagingAmount !== 0) {
        waste_category.push({
          name: "hard_packing_bags_dry",
          kg: Number(hardPackagingKg),
          deduction: 0,
        });
      }

      if (isHdpeToggled && totalHdpe !== 0) {
        waste_category.push({
          name: "hdpe_plastics_below_five_meters",
          kg: Number(formManual.hdpe),
          deduction: 0,
        });
      }

      if (!isHardToggled && hdpeAmount !== 0) {
        waste_category.push({
          name: "hdpe_plastics_below_five_meters",
          kg: Number(hdpeKg),
          deduction: 0,
        });
      }

      if (iscansToggled && totalCans !== 0) {
        waste_category.push({
          name: "cans",
          kg: Number(formManual.cans),
          deduction: 0,
        });
      }

      if (!iscansToggled && cansAmount !== 0) {
        waste_category.push({
          name: "cans",
          kg: Number(cansKg),
          deduction: 0,
        });
      }

      const payload = {
        waste_category: waste_category,
        dropoff_id: id,
        payment_method: dropOffData?.owner?.roles[0] === "corporate" ? "transfer" : selectedMethod,
        request_type: "dropoff",
        manual_amount: totalManual,
      };

      // console.log(payload);

      axios
        .post(
          `${switchEndpoint}/collection-center/initiate-transaction`,
          payload,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          localStorage.setItem("dropOff", JSON.stringify(response.data));
          localStorage.setItem("totalDed", JSON.stringify(totalDeduction));
          // console.log(response.data);
          if (dropOffData?.owner?.user_type === "unregistered") {
            setLoading(false);
            handleCloseDrop();
            setFormManual({
              sorted: "",
              unsorted: "",
              mixed: "",
              pureWater: "",
              singleColor: "",
              hardPackaging: "",
              hdpe: "",
              pp: "",
              packagingBag: "",
              cans: "",
              manualSorted: "",
              manualUnsorted: "",
              manualMixed: "",
              manualSinglePureWater: "",
              manualSingleColor: "",
              manualHardPackaging: "",
              manualHdpe: "",
              manualPp: "",
              manualPackagingBag: "",
              manualCans: "",
            });
            setTotalSorted(0);
            setTotalUnsorted(0);
            setTotalMixed(0);
            setTotalPure(0);
            setTotalSingleColor(0);
            setTotalHard(0);
            setTotalHdpe(0);
            setTotalPp(0);
            setTotalPackaging(0);
            setTotalCans(0);
            setTotalManual(0);
            setIsCsToggled(false);
            setIsCUToggled(false);
            setIsMixedToggled(false);
            setIsPureToggled(false);
            setIsSingleToggled(false);
            setIsHardToggled(false);
            setIsHdpeToggled(false);
            setIsPpToggled(false);
            setIsPackagingToggled(false);
            setIscansToggled(false);
            setCleanSortedKg("");
            setCleanUnsortedKg("");
            setMixedPlasticsKg("");
            setPureWaterKg("");
            setSingleColorKg("");
            setHardPackagingKg("");
            setHdpeKg("");
            setPpKg("");
            setPackagingBagKg("");
            setCansKg("");
            setCleanSortedAmount(null);
            setCleanUnsortedAmount(null);
            setMixedPlasticsAmount(null);
            setPureWaterAmount(null);
            setSingleColorAmount(null);
            setHardPackagingAmount(null);
            setHdpeAmount(null);
            setPpAmount(null);
            setPackagingBagAmount(null);
            setCansAmount(null);
            handleShowPaymentRR();
            setSelectedCategories([]);
          } else {
            setSteps(1);
            setLoading(false);
            handleCloseDrop();
            setForm({
              sorted: "",
              unsorted: "",
              mixed: "",
              pureWater: "",
              singleColor: "",
              hardPackaging: "",
              hdpe: "",
              pp: "",
              cans: "",
              packagingBag: "",
              deductSorted: "",
              deductUnsorted: "",
              deductMixed: "",
              deductSinglePureWater: "",
              deductSingleColor: "",
              deductHardPackaging: "",
              deductHdpe: "",
              deductPp: "",
              deductCans: "",
            });
            setDeductSS("");
            setSortedValue(0);
            setUnsortedValue(0);
            setMixedValue(0);
            setPureWaterValue(0);
            setSingleColorValue(0);
            setHardPackagingValue(0);
            setHdpeValue(0);
            setPpValue(0);
            setPackagingBagValue(0);
            setCansValue(0);
            setDeduction1(false);
            setDeduction2(false);
            setDeduction3(false);
            setDeduction4(false);
            setDeduction5(false);
            setDeduction6(false);
            setDeduction7(false);
            setDeduction8(false);
            setDeduction9(false);
            setDeduction10(false);
            setTotalDeduction(0);
            setTotalManual(0);
            setIsCsToggled(false);
            setIsCUToggled(false);
            setIsMixedToggled(false);
            setIsPureToggled(false);
            setIsSingleToggled(false);
            setIsHardToggled(false);
            setIsHdpeToggled(false);
            setIsPpToggled(false);
            setIsPackagingToggled(false);
            setIscansToggled(false);
            setCleanSortedKg("");
            setCleanUnsortedKg("");
            setMixedPlasticsKg("");
            setPureWaterKg("");
            setSingleColorKg("");
            setHardPackagingKg("");
            setHdpeKg("");
            setPpKg("");
            setPackagingBagKg("");
            setCansKg("");
            setCleanSortedAmount(null);
            setCleanUnsortedAmount(null);
            setMixedPlasticsAmount(null);
            setPureWaterAmount(null);
            setSingleColorAmount(null);
            setHardPackagingAmount(null);
            setHdpeAmount(null);
            setPpAmount(null);
            setPackagingBagAmount(null);
            setCansAmount(null);
            setSelectedMethod("");
            handleShowAwaiting();
            setSelectedCategories([]);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            pauseOnHover: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        });
    }
  };

  //for manual pricing
  const [totalSorted, setTotalSorted] = useState(0);
  const [totalUnsorted, setTotalUnsorted] = useState(0);
  const [totalMixed, setTotalMixed] = useState(0);
  const [totalPure, setTotalPure] = useState(0);
  const [totalSingleColor, setTotalSingleColor] = useState(0);
  const [totalHard, setTotalHard] = useState(0);
  const [totalHdpe, setTotalHdpe] = useState(0);
  const [totalPp, setTotalPp] = useState(0);
  const [totalPackaging, setTotalPackaging] = useState(0);
  const [totalCans, setTotalCans] = useState(0);
  const [totalManual, setTotalManual] = useState(0);

  const [formManual, setFormManual] = useState({
    sorted: "",
    unsorted: "",
    mixed: "",
    pureWater: "",
    singleColor: "",
    hardPackaging: "",
    hdpe: "",
    pp: "",
    packagingBag: "",
    cans: "",
    manualSorted: "",
    manualUnsorted: "",
    manualMixed: "",
    manualSinglePureWater: "",
    manualSingleColor: "",
    manualHardPackaging: "",
    manualHdpe: "",
    manualPp: "",
    manualPackagingBag: "",
    manualCans: "",
  });

  const [isCsToggled, setIsCsToggled] = useState(false);
  const [isCUToggled, setIsCUToggled] = useState(false);
  const [isMixedToggled, setIsMixedToggled] = useState(false);
  const [isPureToggled, setIsPureToggled] = useState(false);
  const [isSingleToggled, setIsSingleToggled] = useState(false);
  const [isHardToggled, setIsHardToggled] = useState(false);
  const [isHdpeToggled, setIsHdpeToggled] = useState(false);
  const [isPpToggled, setIsPpToggled] = useState(false);
  const [isPackagingToggled, setIsPackagingToggled] = useState(false);
  const [iscansToggled, setIscansToggled] = useState(false);

  const calculateSortedVal1 = () => {
    let price =
      getPricing(pricing, "clean_sorted", cleanSortedKg, "dropoff") *
      cleanSortedKg;
    setCleanSortedAmount(price);
  };

  useEffect(() => {
    calculateSortedVal1();
  }, [cleanSortedKg]);

  const calculateUnsortedVal1 = () => {
    let price =
      getPricing(pricing, "clean_unsorted", cleanUnsortedKg, "dropoff") *
      cleanUnsortedKg;
    setCleanUnsortedAmount(price);
  };

  useEffect(() => {
    calculateUnsortedVal1();
  }, [cleanUnsortedKg]);

  const calculateMixedPlasticsVal1 = () => {
    let price =
      getPricing(pricing, "mixed_plastics", mixedPlasticsKg, "dropoff") *
      mixedPlasticsKg;
    setMixedPlasticsAmount(price);
  };

  useEffect(() => {
    calculateMixedPlasticsVal1();
  }, [mixedPlasticsKg]);

  const pureWaterVal1 = () => {
    let price =
      getPricing(pricing, "pure_water_sachet_dry", pureWaterKg, "dropoff") *
      pureWaterKg;
    setPureWaterAmount(price);
  };

  useEffect(() => {
    pureWaterVal1();
  }, [pureWaterKg]);

  const singleColorVal1 = () => {
    let price =
      getPricing(pricing, "single_colour_caps", singleColorKg, "dropoff") *
      singleColorKg;
    setSingleColorAmount(price);
  };

  useEffect(() => {
    singleColorVal1();
  }, [singleColorKg]);

  const hardPackagingVal1 = () => {
    let price =
      getPricing(
        pricing,
        "hard_packing_bags_dry",
        hardPackagingKg,
        "dropoff"
      ) * hardPackagingKg;
    setHardPackagingAmount(price);
  };

  useEffect(() => {
    hardPackagingVal1();
  }, [hardPackagingKg]);

  const hdpeVal1 = () => {
    let price =
      getPricing(
        pricing,
        "hdpe_plastics_below_five_meters",
        hdpeKg,
        "dropoff"
      ) * hdpeKg;
    setHdpeAmount(price);
  };

  useEffect(() => {
    hdpeVal1();
  }, [hdpeKg]);

  const ppVal1 = () => {
    let price = getPricing(pricing, "pp_plastics", ppKg, "dropoff") * ppKg;
    setPpAmount(price);
  };

  useEffect(() => {
    ppVal1();
  }, [ppKg]);

  const packagingBagVal1 = () => {
    let price =
      getPricing(pricing, "packing_bags", packagingBagKg, "dropoff") *
      packagingBagKg;
    setPackagingBagAmount(price);
  };

  useEffect(() => {
    packagingBagVal1();
  }, [packagingBagKg]);

  const cansVal1 = () => {
    let price = getPricing(pricing, "cans", cansKg, "dropoff") * cansKg;
    setCansAmount(price);
  };

  useEffect(() => {
    cansVal1();
  }, [cansKg]);

  const handleManualSorted = (val1, val2) => {
    const final = val1 * val2;
    setTotalSorted(final);
  };

  useEffect(() => {
    handleManualSorted(formManual.sorted, formManual.manualSorted);
  }, [formManual.sorted, formManual.manualSorted]);

  const handleManualUnsorted = (val1, val2) => {
    const final = val1 * val2;
    setTotalUnsorted(final);
  };

  useEffect(() => {
    handleManualUnsorted(formManual.unsorted, formManual.manualUnsorted);
  }, [formManual.unsorted, formManual.manualUnsorted]);

  const handleManualMixed = (val1, val2) => {
    const final = val1 * val2;
    setTotalMixed(final);
  };

  useEffect(() => {
    handleManualMixed(formManual.mixed, formManual.manualMixed);
  }, [formManual.mixed, formManual.manualMixed]);

  const handleManualPure = (val1, val2) => {
    const final = val1 * val2;
    setTotalPure(final);
  };

  useEffect(() => {
    handleManualPure(formManual.pureWater, formManual.manualSinglePureWater);
  }, [formManual.pureWater, formManual.manualSinglePureWater]);

  const handleManualSingle = (val1, val2) => {
    const final = val1 * val2;
    setTotalSingleColor(final);
  };

  useEffect(() => {
    handleManualSingle(formManual.singleColor, formManual.manualSingleColor);
  }, [formManual.singleColor, formManual.manualSingleColor]);

  const handleManualHard = (val1, val2) => {
    const final = val1 * val2;
    setTotalHard(final);
  };

  useEffect(() => {
    handleManualHard(formManual.hardPackaging, formManual.manualHardPackaging);
  }, [formManual.hardPackaging, formManual.manualHardPackaging]);

  const handleManualHdpe = (val1, val2) => {
    const final = val1 * val2;
    setTotalHdpe(final);
  };

  useEffect(() => {
    handleManualHdpe(formManual.hdpe, formManual.manualHdpe);
  }, [formManual.hdpe, formManual.manualHdpe]);

  const handleManualPP = (val1, val2) => {
    const final = val1 * val2;
    setTotalPp(final);
  };

  useEffect(() => {
    handleManualPP(formManual.pp, formManual.manualPp);
  }, [formManual.pp, formManual.manualPp]);

  const handleManualPackaging = (val1, val2) => {
    const final = val1 * val2;
    setTotalPackaging(final);
  };

  useEffect(() => {
    handleManualPackaging(
      formManual.packagingBag,
      formManual.manualPackagingBag
    );
  }, [formManual.packagingBag, formManual.manualPackagingBag]);

  const handleManualCans = (val1, val2) => {
    const final = val1 * val2;
    setTotalCans(final);
  };

  useEffect(() => {
    handleManualCans(formManual.cans, formManual.manualCans);
  }, [formManual.cans, formManual.manualCans]);

  const handleManualFormChange =
    (name) =>
      ({ target }) => {
        if (/^(\d+(\.\d{0,2})?)?$/.test(target.value)) {
          setFormManual({
            ...formManual,
            [name]: target.value,
          });
        }
      };

  const finalManual = (
    val1,
    val2,
    val3,
    val4,
    val5,
    val6,
    val7,
    val8,
    val9,
    val10
  ) => {
    const total =
      Number(val1) +
      Number(val2) +
      Number(val3) +
      Number(val4) +
      Number(val5) +
      Number(val6) +
      Number(val7) +
      Number(val8) +
      Number(val9) +
      Number(val10);
    const dd = Math.round((total + Number.EPSILON) * 100) / 100;
    setTotalManual(dd);
  };

  useEffect(() => {
    finalManual(
      isCsToggled ? totalSorted : cleanSortedAmount,
      isCUToggled ? totalUnsorted : cleanUnsortedAmount,
      isMixedToggled ? totalMixed : mixedPlasticsAmount,
      isPureToggled ? totalPure : pureWaterAmount,
      isSingleToggled ? totalSingleColor : singleColorAmount,
      isHardToggled ? totalHard : hardPackagingAmount,
      isHdpeToggled ? totalHdpe : hdpeAmount,
      isPpToggled ? totalPp : ppAmount,
      isPackagingToggled ? totalPackaging : packagingBagAmount,
      iscansToggled ? totalCans : cansAmount
    );
  }, [
    isCsToggled ? totalSorted : cleanSortedAmount,
    isCUToggled ? totalUnsorted : cleanUnsortedAmount,
    isMixedToggled ? totalMixed : mixedPlasticsAmount,
    isPureToggled ? totalPure : pureWaterAmount,
    isSingleToggled ? totalSingleColor : singleColorAmount,
    isHardToggled ? totalHard : hardPackagingAmount,
    isHdpeToggled ? totalHdpe : hdpeAmount,
    isPpToggled ? totalPp : ppAmount,
    isPackagingToggled ? totalPackaging : packagingBagAmount,
    iscansToggled ? totalCans : cansAmount
  ]);

  useEffect(() => {
    if (isCsToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        sorted: cleanSortedKg,
      }));
    } else {
      setCleanSortedKg(formManual.sorted);
      setTotalSorted(cleanSortedAmount);
      setTotalSorted(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isCsToggled]);

  useEffect(() => {
    if (isCUToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        unsorted: cleanUnsortedKg,
      }));
    } else {
      setCleanUnsortedKg(formManual.unsorted);
      setTotalUnsorted(cleanUnsortedAmount);
      setTotalUnsorted(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isCUToggled]);

  useEffect(() => {
    if (isMixedToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        mixed: mixedPlasticsKg,
      }));
    } else {
      setMixedPlasticsKg(formManual.mixed);
      setTotalMixed(mixedPlasticsAmount);
      setTotalMixed(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isMixedToggled]);

  useEffect(() => {
    if (isPureToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        pureWater: pureWaterKg,
      }));
    } else {
      setPureWaterKg(formManual.pureWater);
      setTotalPure(pureWaterAmount);
      setTotalPure(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isPureToggled]);

  useEffect(() => {
    if (isSingleToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        singleColor: singleColorKg,
      }));
    } else {
      setSingleColorKg(formManual.singleColor);
      setTotalSingleColor(pureWaterAmount);
      setTotalSingleColor(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isSingleToggled]);

  useEffect(() => {
    if (isHardToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        hardPackaging: hardPackagingKg,
      }));
    } else {
      setHardPackagingKg(formManual.hardPackaging);
      setTotalHard(hardPackagingAmount);
      setTotalHard(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isHardToggled]);

  useEffect(() => {
    if (isHdpeToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        hdpe: hdpeKg,
      }));
    } else {
      setHdpeKg(formManual.hdpe);
      setTotalHdpe(hdpeAmount);
      setTotalHdpe(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isHdpeToggled]);

  useEffect(() => {
    if (isPpToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        pp: ppKg,
      }));
    } else {
      setPpKg(formManual.pp);
      setTotalPp(ppAmount);
      setTotalPp(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isPpToggled]);

  useEffect(() => {
    if (isPackagingToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        packagingBag: packagingBagKg,
      }));
    } else {
      setPackagingBagKg(formManual.packagingBag);
      setTotalPackaging(packagingBagAmount);
      setTotalPackaging(0);
      setFormManual({ manualSorted: "" });
    }
  }, [isPackagingToggled]);

  useEffect(() => {
    if (iscansToggled) {
      setFormManual((prevState) => ({
        ...prevState,
        cans: cansKg,
      }));
    } else {
      setCansKg(formManual.cans);
      setTotalCans(cansAmount);
      setTotalCans(0);
      setFormManual({ manualSorted: "" });
    }
  }, [iscansToggled]);
  return (
    <div>
      <Modal
        show={showDrop}
        onHide={() => {
          setSelectedCategories([]);
          setForm({
            sorted: "",
            unsorted: "",
            mixed: "",
            pureWater: "",
            singleColor: "",
            hardPackaging: "",
            hdpe: "",
            pp: "",
            cans: "",
            deductSorted: "",
            deductUnsorted: "",
            deductMixed: "",
            deductSinglePureWater: "",
            deductSingleColor: "",
            deductHardPackaging: "",
            deductHdpe: "",
            deductPp: "",
            deductCans: "",
          });
          setSortedValue(0);
          setUnsortedValue(0);
          setMixedValue(0);
          setPureWaterValue(0);
          setSingleColorValue(0);
          setHardPackagingValue(0);
          setHdpeValue(0);
          setPpValue(0);
          setPackagingBagValue(0);
          setCansValue(0);
          setDeduction1(false);
          setDeduction2(false);
          setDeduction3(false);
          setDeduction4(false);
          setDeduction5(false);
          setDeduction6(false);
          setDeduction7(false);
          setDeduction8(false);
          setDeduction9(false);
          setDeduction10(false);
          setFinalValue(0)
          handleCloseDrop();
          setDeductSS("");
          getHouseholdReq();
          getAgentReq();
          setFormManual({
            sorted: "",
            unsorted: "",
            mixed: "",
            pureWater: "",
            singleColor: "",
            hardPackaging: "",
            hdpe: "",
            pp: "",
            packagingBag: "",
            cans: "",
            manualSorted: "",
            manualUnsorted: "",
            manualMixed: "",
            manualSinglePureWater: "",
            manualSingleColor: "",
            manualHardPackaging: "",
            manualHdpe: "",
            manualPp: "",
            manualPackagingBag: "",
            manualCans: "",
          })
          setTotalSorted(0);
          setTotalUnsorted(0);
          setTotalMixed(0);
          setTotalPure(0);
          setTotalSingleColor(0);
          setTotalHard(0);
          setTotalHdpe(0);
          setTotalPp(0);
          setTotalPackaging(0);
          setTotalCans(0);
          setTotalManual(0);
          setIsCsToggled(false);
          setIsCUToggled(false);
          setIsMixedToggled(false);
          setIsPureToggled(false);
          setIsSingleToggled(false);
          setIsHardToggled(false);
          setIsHdpeToggled(false);
          setIsPpToggled(false);
          setIsPackagingToggled(false);
          setIscansToggled(false);
          setCleanSortedKg("");
          setCleanUnsortedKg("");
          setMixedPlasticsKg("");
          setPureWaterKg("");
          setSingleColorKg("");
          setHardPackagingKg("");
          setHdpeKg("");
          setPpKg("");
          setPackagingBagKg("");
          setCansKg("");
          setCleanSortedAmount(null);
          setCleanUnsortedAmount(null);
          setMixedPlasticsAmount(null);
          setPureWaterAmount(null);
          setSingleColorAmount(null);
          setHardPackagingAmount(null);
          setHdpeAmount(null);
          setPpAmount(null);
          setPackagingBagAmount(null);
          setCansAmount(null);
          setCleanSortedDeduction("");
          setCleanUnsortedDeduction("");
          setMixedPlasticsDeduction("");
          setPureWaterDeduction("");
          setSingleColorDeduction("");
          setHardPackagingDeduction("");
          setHdpeDeduction("");
          setPpDeduction("");
          setPackagingBagDeduction("");
          setCansDeduction("");
          // window.location.reload();
        }}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="text-left ml-3">
            <div className="mb-2">
              <h5 className="sec-headers mb-3">Weigh Used Plastics</h5>
              <div className="d-flex justify-content-between align-items-center">
                <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                  {capitalize(dropOffData?.owner?.roles[0])}:{" "}
                  {dropOffData?.owner?.name}
                </p>

                <div className="d-flex align-items-center ">
                  <div>
                    <p
                      className="mb-0"
                      style={{ fontSize: "14px", color: "#898989" }}
                    >
                      Drop off Code
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#404040",
                        fontWeight: "700",
                      }}
                    >
                      {dropOffData?.code}
                    </p>
                  </div>
                  <div className="imgAvatarHolder ml-3">
                    <img src={Avatar} alt="image" />
                  </div>
                </div>
              </div>
              <p className="ftsz-regular" style={{ fontSize: "14px" }}>
                Used Plastic Categories
              </p>
            </div>

            {isManual === "true" ? (
              <div>
                <div className="category-selector">
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle w-75"
                    placeholder={placeholderText}
                    onClick={handleInputClick}
                    readOnly
                  />
                  {isDropdownOpen && (
                    <div
                      ref={dropdownRef}
                      className="dropdown mt-2"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {categories.map((category, index) => (
                        <div key={category} className="dropdown-item">
                          <label className=" d-flex">
                            <input
                              type="checkbox"
                              id={`checkbox-${index}`} // Unique ID for each checkbox
                              checked={selectedCategories.includes(category)}
                              onChange={() => handleCheckboxChange(category)}
                            />
                            <p className="mb-0 pl-2">{category}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {selectedCategories.includes("Clean Sorted") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">Clean Sorted</label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={
                            isCsToggled ? formManual.sorted : cleanSortedKg
                          }
                          onChange={
                            isCsToggled
                              ? handleManualFormChange("sorted")
                              : (e) => setCleanSortedKg(e.target.value)
                          }
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={
                            isCsToggled
                              ? `₦${totalSorted === null || !totalSorted
                                ? 0
                                : totalSorted
                              }`
                              : `₦${cleanSortedAmount === null || !cleanSortedAmount
                                ? 0
                                : cleanSortedAmount
                              }`
                          }
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 
                          ${deduction1 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isCsToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsCsToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isCsToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>
                      {isCsToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualSorted}
                            onChange={handleManualFormChange("manualSorted")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Clean Unsorted") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Clean Unsorted
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isCUToggled ? formManual.unsorted : cleanUnsortedKg}
                          onChange={isCUToggled ? handleManualFormChange("unsorted") : (e) => setCleanUnsortedKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isCUToggled ? `₦${totalUnsorted === null || !totalUnsorted ? 0 : totalUnsorted
                            }` : `₦${cleanUnsortedAmount === null || !cleanUnsortedAmount
                              ? 0
                              : cleanUnsortedAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 
                          ${deduction1 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isCUToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsCUToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isCUToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>
                      {isCUToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder=" Manual Price (per KG)"
                            value={formManual.manualUnsorted}
                            onChange={handleManualFormChange("manualUnsorted")}
                          />
                        </div>)}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Mixed Plastics") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Mixed Plastics
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isMixedToggled ? formManual.mixed : mixedPlasticsKg}
                          onChange={isMixedToggled ? handleManualFormChange("mixed") : (e) => setMixedPlasticsKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isMixedToggled ? `₦${totalMixed === null || !totalMixed ? 0 : totalMixed.toFixed(2)
                            }` : `₦${mixedPlasticsAmount === null || !mixedPlasticsAmount
                              ? 0
                              : mixedPlasticsAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction3 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isMixedToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsMixedToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isMixedToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>
                      {isMixedToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder=" Manual Price (per KG)"
                            value={formManual.manualMixed}
                            onChange={handleManualFormChange("manualMixed")}
                          />
                        </div>
                      )}

                    </div>
                  </div>
                )}

                {selectedCategories.includes("Pure Water Nylon") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Pure Water Nylon
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isPureToggled ? formManual.pureWater : pureWaterKg}
                          onChange={isPureToggled ? handleManualFormChange("pureWater") : (e) => setPureWaterKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isPureToggled ? `₦${totalPure === null || !totalPure ? 0 : totalPure.toFixed(2)
                            }` : `₦${pureWaterAmount === null || !pureWaterAmount
                              ? 0
                              : pureWaterAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction4 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isPureToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsPureToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isPureToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>

                      {isPureToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualSinglePureWater}
                            onChange={handleManualFormChange(
                              "manualSinglePureWater"
                            )}
                          />
                        </div>
                      )}

                    </div>
                  </div>
                )}

                {selectedCategories.includes("Single color caps") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Single color caps
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isSingleToggled ? formManual.singleColor : singleColorKg}
                          onChange={isSingleToggled ? handleManualFormChange("singleColor") : (e) => setSingleColorKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isSingleToggled ? `₦${totalSingleColor === null || !totalSingleColor
                            ? 0
                            : totalSingleColor.toFixed(2)
                            }` : `₦${singleColorAmount === null || !singleColorAmount
                              ? 0
                              : singleColorAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction5 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isSingleToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsSingleToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isSingleToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>

                      {isSingleToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualSingleColor}
                            onChange={handleManualFormChange("manualSingleColor")}
                          />
                        </div>
                      )}

                    </div>
                  </div>
                )}

                {selectedCategories.includes("Hard packing bags (dry)") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Hard packing bags (dry)
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isHardToggled ? formManual.hardPackaging : hardPackagingKg}
                          onChange={isHardToggled ? handleManualFormChange("hardPackaging") : (e) => setHardPackagingKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isHardToggled ? `₦${totalHard === null || !totalHard ? 0 : totalHard.toFixed(2)
                            }` : `₦${hardPackagingAmount === null || !hardPackagingAmount
                              ? 0
                              : hardPackagingAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction6 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isHardToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsHardToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isHardToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>

                      {isHardToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualHardPackaging}
                            onChange={handleManualFormChange(
                              "manualHardPackaging"
                            )}
                          />
                        </div>
                      )}

                    </div>
                  </div>
                )}

                {selectedCategories.includes(
                  "HDPE plastics below 5 Litres"
                ) && (
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mb-3">
                          <label className="mb-1 form-label">
                            HDPE plastics below 5 Litres
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Enter weight in KG"
                            value={isHdpeToggled ? formManual.hdpe : hdpeKg}
                            onChange={isHdpeToggled ? handleManualFormChange("hdpe") : (e) => setHdpeKg(e.target.value)}
                          />
                        </div>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <div className="mb-3 mr-3">
                          <label className="mb-1 form-label">
                            Amount in Naira
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle bg-dark text-white"
                            placeholder="N0.00"
                            value={isHdpeToggled ? `₦${totalHdpe === null || !totalHdpe ? 0 : totalHdpe.toFixed(2)
                              }` : `₦${hdpeAmount === null || !hdpeAmount
                                ? 0
                                : hdpeAmount
                              }`}
                            disabled
                          />
                        </div>

                        <div
                          className={`mb-0 ${deduction7 ? "percentageDark" : "percentge"
                            } d-flex align-items-center justify-content-center h-0`}
                          style={{ cursor: "pointer", backgroundColor: `${isHdpeToggled ? "#404040" : "#C4DA00"}` }}
                          onClick={() => {
                            setIsHdpeToggled((prevState) => !prevState);
                          }}
                        >
                          <p className="mb-0 ">
                            <MdOutlineModeEdit
                              size={20}
                              color={isHdpeToggled ? "#C4DA00" : "#fff"}
                            />
                          </p>
                        </div>

                        {isHdpeToggled && (
                          <div className="mb-3 ml-3">
                            <label className="mb-1 form-label">
                              Manual Price (per KG)
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg inputStyle"
                              placeholder="Manual Price (per KG)"
                              value={formManual.manualHdpe}
                              onChange={handleManualFormChange("manualHdpe")}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {selectedCategories.includes("PP Plastics") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">PP Plastics</label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isPpToggled ? formManual.pp : ppKg}
                          onChange={isPpToggled ? handleManualFormChange("pp") : (e) => setPpKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isPpToggled ? `₦${totalPp === null || !totalPp ? 0 : totalPp.toFixed(2)
                            }` : `₦${ppAmount === null || !ppAmount
                              ? 0
                              : ppAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction8 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isPpToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsPpToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isPpToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>

                      {isPpToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualPp}
                            onChange={handleManualFormChange("manualPp")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Pure Water Packaging Bags") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          {" "}
                          Pure Water Packaging Bags
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={isPackagingToggled ? formManual.packagingBag : packagingBagKg}
                          onChange={isPackagingToggled ? handleManualFormChange("packagingBag") : (e) => setPackagingBagKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={isPackagingToggled ? `₦${totalPackaging === null || !totalPackaging
                            ? 0
                            : totalPackaging.toFixed(2)
                            }` : `₦${packagingBagAmount === null || !packagingBagAmount
                              ? 0
                              : packagingBagAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction9 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${isPackagingToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIsPackagingToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={isPackagingToggled ? "#C4DA00" : "#fff"}
                          />
                        </p>
                      </div>

                      {isPackagingToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualPackagingBag}
                            onChange={handleManualFormChange(
                              "manualPackagingBag"
                            )}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Cans") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">Cans</label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={iscansToggled ? formManual.cans : cansKg}
                          onChange={iscansToggled ? handleManualFormChange("cans") : (e) => setCansKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={iscansToggled ? `₦${totalCans === null || !totalCans ? 0 : totalCans.toFixed(2)
                            }` : `₦${cansAmount === null || !cansAmount
                              ? 0
                              : cansAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction10 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer", backgroundColor: `${iscansToggled ? "#404040" : "#C4DA00"}` }}
                        onClick={() => {
                          setIscansToggled((prevState) => !prevState);
                        }}
                      >
                        <p className="mb-0 "> <p className="mb-0 ">
                          <MdOutlineModeEdit
                            size={20}
                            color={iscansToggled ? "#C4DA00" : "#fff"}
                          />
                        </p></p>
                      </div>

                      {iscansToggled && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Manual Price (per KG)
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Manual Price (per KG)"
                            value={formManual.manualCans}
                            onChange={handleManualFormChange("manualCans")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div className="d-flex align-items-center">
                  <div className="mb-3 mr-3 total">
                    <label className="mb-1 form-label">Sum Total</label>
                    <input
                      type="text"
                      className="form-control form-control-lg inputStyle bg-dark text-white"
                      placeholder="N0.00"
                      value={totalManual === null || !totalManual ? `₦${0}` : `₦${totalManual}`}
                      disabled
                    />
                  </div>

                  {dropOffData?.owner?.roles[0] !== "corporate" ? (
                    <div className="mb-3 mr-3 total pt-2">
                      {dropOffData?.owner?.user_type === "unregistered" ||
                        !dropOffData?.owner?.account?.account_number ? (
                        <div className="">
                          <div className="form-group">
                            <label for="exampleFormControlSelect1">
                              Select Payment Method
                            </label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) => {
                                setSelectedMethod(e.target.value);
                                // console.log(e.target.value);
                                localStorage.setItem(
                                  "paymentMethod",
                                  e.target.value
                                );
                              }}
                              value={selectedMethod}
                            >
                              <option value="">Select</option>
                              <option value="cash">Cash</option>
                            </select>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          <div className="form-group">
                            <label for="exampleFormControlSelect1">
                              Select Payment Method
                            </label>
                            <select
                              className="form-control"
                              id="exampleFormControlSelect1"
                              onChange={(e) => {
                                setSelectedMethod(e.target.value);
                                // console.log(e.target.value);
                                localStorage.setItem(
                                  "paymentMethod",
                                  e.target.value
                                );
                              }}
                              value={selectedMethod}
                            >
                              <option value="">Select</option>
                              <option value="transfer">
                                Transfer To Switch Wallet
                              </option>
                              <option value="cash">Cash</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mr-3 total">
                      <div className="form-group mb-0">
                        <label className="pt-3" for="exampleFormControlSelect1">
                          EOM Suspense Account
                        </label>
                      </div>
                      <div
                        className="mb-3 pt-2 pl-2"
                        style={{
                          width: "250px",
                          height: "40px",
                          border: "1px solid #e3e3e3",
                          backgroundColor: "#e9ecef",
                        }}
                      >
                        <p>{dropOffData?.owner?.payment_option === "instant" ? "Instant Payment" : "EOM"}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="mt-1 mb-5 w-btn">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block"
                    onClick={() => {
                      if (dropOffData?.owner?.roles[0] === "corporate") {
                        localStorage.setItem(
                          "paymentMethod",
                          "transfer"
                        );
                      }
                      initiateManualDropOff();
                    }}
                    disabled={loading || selectedCategories.length <= 0}
                  >
                    Initiate Payment
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <div className="category-selector">
                  <input
                    type="text"
                    className="form-control form-control-lg inputStyle w-75"
                    placeholder={placeholderText}
                    onClick={handleInputClick}
                    readOnly
                  />

                  {isDropdownOpen && (
                    <div
                      ref={dropdownRef}
                      className="dropdown mt-2"
                      onClick={(event) => event.stopPropagation()}
                    >
                      {categories.map((category, index) => (
                        <div key={category} className="dropdown-item">
                          <label className=" d-flex">
                            <input
                              type="checkbox"
                              id={`checkbox-${index}`} // Unique ID for each checkbox
                              checked={selectedCategories.includes(category)}
                              onChange={() => handleCheckboxChange(category)}
                            />
                            <p className="mb-0 pl-2">{category}</p>
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                {selectedCategories.includes("Clean Sorted") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">Clean Sorted</label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={cleanSortedKg}
                          onChange={(e) => setCleanSortedKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${cleanSortedAmount === null ? 0 : cleanSortedAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction1 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction1(!deduction1);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction1 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={cleanSortedDeduction}
                            onChange={handleInputChangeCS}
                            disabled={
                              cleanSortedKg === "" || cleanSortedKg === 0
                            }
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Clean Unsorted") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Clean Unsorted
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={cleanUnsortedKg}
                          onChange={(e) => setCleanUnsortedKg(e.target.value)}
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${cleanUnsortedAmount === null
                            ? 0
                            : cleanUnsortedAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction2 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction2(!deduction2);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction2 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={cleanUnsortedDeduction}
                            onChange={handleInputChangeCU}
                            disabled={
                              cleanUnsortedKg === "" || cleanUnsortedKg === 0
                            }
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Mixed Plastics") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Mixed Plastics
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={mixedPlasticsKg}
                          onChange={(e) => setMixedPlasticsKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${mixedPlasticsAmount === null
                            ? 0
                            : mixedPlasticsAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction3 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction3(!deduction3);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction3 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={mixedPlasticsDeduction}
                            onChange={handleInputChangeMx}
                            disabled={
                              mixedPlasticsKg === "" || mixedPlasticsKg === 0
                            }
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Pure Water Nylon") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Pure Water Nylon
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={pureWaterKg}
                          onChange={(e) => setPureWaterKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${pureWaterAmount === null ? 0 : pureWaterAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction4 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction4(!deduction4);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction4 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={pureWaterDeduction}
                            onChange={handleInputChangePw}
                            disabled={pureWaterKg === "" || pureWaterKg === 0}
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Single color caps") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Single color caps
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={singleColorKg}
                          onChange={(e) => setSingleColorKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${singleColorAmount === null ? 0 : singleColorAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction5 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction5(!deduction5);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction5 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={singleColorDeduction}
                            onChange={handleInputChangeSC}
                            disabled={
                              singleColorKg === "" || singleColorKg === 0
                            }
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Hard packing bags (dry)") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Hard packing bags (dry)
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={hardPackagingKg}
                          onChange={(e) => setHardPackagingKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${hardPackagingAmount === null
                            ? 0
                            : hardPackagingAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction6 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction6(!deduction6);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction6 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={hardPackagingDeduction}
                            onChange={handleInputChangeHP}
                            disabled={
                              hardPackagingKg === "" || hardPackagingKg === 0
                            }
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes(
                  "HDPE plastics below 5 Litres"
                ) && (
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="mb-3">
                          <label className="mb-1 form-label">
                            HDPE plastics below 5 Litres
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="Enter weight in KG"
                            value={hdpeKg}
                            onChange={(e) => setHdpeKg(e.target.value)}
                          // disabled
                          />
                        </div>
                        <span className="mx-3" style={{ color: "#c4da00" }}>
                          &#8644;
                        </span>
                        <div className="mb-3 mr-3">
                          <label className="mb-1 form-label">
                            Amount in Naira
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle bg-dark text-white"
                            placeholder="N0.00"
                            value={`₦${hdpeAmount === null ? 0 : hdpeAmount}`}
                            disabled
                          />
                        </div>

                        <div
                          className={`mb-0 ${deduction7 ? "percentageDark" : "percentge"
                            } d-flex align-items-center justify-content-center h-0`}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setDeduction7(!deduction7);
                          }}
                        >
                          <p className="mb-0 ">%</p>
                        </div>

                        {deduction7 && (
                          <div className="mb-3 ml-3">
                            <label className="mb-1 form-label">
                              Deduction in Percentage
                            </label>
                            <input
                              type="text"
                              className="form-control form-control-lg inputStyle"
                              placeholder="% Deduction"
                              value={hdpeDeduction}
                              onChange={handleInputChangeHD}
                              disabled={hdpeKg === "" || hdpeKg === 0}
                              maxLength={1}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {selectedCategories.includes("PP Plastics") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">PP Plastics</label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={ppKg}
                          onChange={(e) => setPpKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${ppAmount === null ? 0 : ppAmount}`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction8 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction8(!deduction8);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction8 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={ppDeduction}
                            onChange={handleInputChangePP}
                            disabled={ppKg === "" || ppKg === 0}
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Pure Water Packaging Bags") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">
                          Pure Water Packaging Bags
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={packagingBagKg}
                          onChange={(e) => setPackagingBagKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${packagingBagAmount === null ? 0 : packagingBagAmount
                            }`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction9 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}

                        onClick={() => {
                          setDeduction9(!deduction9);
                        }}
                        style={{ cursor: "pointer", backgroundColor: `${isCsToggled && "#404040"}` }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction9 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={packagingBagDeduction}
                            onChange={handleInputChangePB}
                            disabled={
                              packagingBagKg === "" || packagingBagKg === 0
                            }
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {selectedCategories.includes("Cans") && (
                  <div>
                    <div className="d-flex align-items-center">
                      <div className="mb-3">
                        <label className="mb-1 form-label">Cans</label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle"
                          placeholder="Enter weight in KG"
                          value={cansKg}
                          onChange={(e) => setCansKg(e.target.value)}
                        // disabled
                        />
                      </div>
                      <span className="mx-3" style={{ color: "#c4da00" }}>
                        &#8644;
                      </span>
                      <div className="mb-3 mr-3">
                        <label className="mb-1 form-label">
                          Amount in Naira
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg inputStyle bg-dark text-white"
                          placeholder="N0.00"
                          value={`₦${cansAmount === null ? 0 : cansAmount}`}
                          disabled
                        />
                      </div>

                      <div
                        className={`mb-0 ${deduction10 ? "percentageDark" : "percentge"
                          } d-flex align-items-center justify-content-center h-0`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeduction10(!deduction10);
                        }}
                      >
                        <p className="mb-0 ">%</p>
                      </div>

                      {deduction10 && (
                        <div className="mb-3 ml-3">
                          <label className="mb-1 form-label">
                            Deduction in Percentage
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg inputStyle"
                            placeholder="% Deduction"
                            value={cansDeduction}
                            onChange={handleInputChangeCans}
                            disabled={cansKg === "" || cansKg === 0}
                            maxLength={1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <div>
                    <div className="mb-3 mr-3 total">
                      <label className="mb-1 form-label">Deductions</label>
                      <input
                        type="text"
                        className="form-control form-control-lg inputStyle bg-dark text-white"
                        placeholder="N0.00"
                        value={`₦${Math.round((totalDeduction + Number.EPSILON) * 100) /
                          100
                          }`}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="mb-3 mr-3 total">
                      <label className="mb-1 form-label">Sum Total</label>
                      <input
                        type="text"
                        className="form-control form-control-lg inputStyle bg-dark text-white"
                        placeholder="N0.00"
                        value={`₦${Math.round((finalValue + Number.EPSILON) * 100) / 100
                          }`}
                        disabled
                      />
                    </div>

                    {dropOffData?.owner?.roles[0] !== "corporate" ? (
                      <div className="mb-3 mr-3 total pt-2">
                        {dropOffData?.owner?.user_type === "unregistered" ||
                          !dropOffData?.owner?.account?.account_number ? (
                          <div className="">
                            <div className="form-group">
                              <label for="exampleFormControlSelect1">
                                Select Payment Method
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => {
                                  setSelectedMethod(e.target.value);
                                  console.log(e.target.value);
                                  localStorage.setItem(
                                    "paymentMethod",
                                    e.target.value
                                  );
                                }}
                                value={selectedMethod}
                              >
                                <option value="">Select</option>
                                <option value="cash">Cash</option>
                              </select>
                            </div>
                          </div>
                        ) : (
                          <div className="mb-3 mr-3 total">
                            <div className="form-group">
                              <label for="exampleFormControlSelect1">
                                Select Payment Method
                              </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) => {
                                  setSelectedMethod(e.target.value);
                                  console.log(e.target.value);
                                  localStorage.setItem(
                                    "paymentMethod",
                                    e.target.value
                                  );
                                }}
                                value={selectedMethod}
                              >
                                <option value="">Select</option>
                                <option value="transfer">
                                  Transfer To Switch Wallet
                                </option>
                                <option value="cash">Cash</option>
                              </select>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="mr-3 total">
                        <div className="form-group mb-0">
                          <label className="pt-3" for="exampleFormControlSelect1">
                            EOM Suspense Account
                          </label>
                        </div>
                        <div
                          className="mb-3 pt-2 pl-2"
                          style={{
                            width: "250px",
                            height: "40px",
                            border: "1px solid #e3e3e3",
                            backgroundColor: "#e9ecef",
                          }}
                        >
                          <p>{dropOffData?.owner?.payment_option === "instant" ? "Instant Payment" : "EOM"}</p>
                        </div>
                      </div>
                    )}


                  </div>
                </div>

                <div className="mt-1 mb-5 w-btn">
                  <button
                    type="button"
                    className="btn loginBtn btn-sm btn-block"
                    onClick={() => {
                      initiateDropOff();
                    }}
                    disabled={loading || selectedCategories.length <= 0}
                  >
                    Initiate Payment
                  </button>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UnregistereDropCodeModal;
